var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0 20px"},attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"demo-drawer__content"},[_c('el-form',{ref:"form",attrs:{"label-position":"top","model":_vm.form}},[_c('required-form-item-select',{ref:"bossRef",attrs:{"prop":"bossId","label":"实控人编号","placeholder":"选择实控人（支持编号/姓名搜索）","labelKey":"realName","valueKey":"id","selectProps":{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        },"total-count":_vm.total,"getDataList":_vm.getBossDataList,"getNextList":_vm.getBossDataList},on:{"onOptionsListNotFound":_vm.onNotFoundBoss,"changeSelectedItem":e => (_vm.bossSelectedItem = e)},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.realName)+"（"+_vm._s(scope.row.bossNumber)+"）")])]}}]),model:{value:(_vm.form.bossId),callback:function ($$v) {_vm.$set(_vm.form, "bossId", $$v)},expression:"form.bossId"}}),_c('el-form-item',{attrs:{"prop":"","label":"实控人"}},[_c('el-input',{attrs:{"placeholder":"根据实控人编号字段自动带出","disabled":""},model:{value:(_vm.bossSelectedItem.bossNumber),callback:function ($$v) {_vm.$set(_vm.bossSelectedItem, "bossNumber", $$v)},expression:"bossSelectedItem.bossNumber"}})],1),_c('el-form-item',{attrs:{"prop":"","label":"实控人身份证"}},[_c('el-input',{attrs:{"placeholder":"根据实控人编号字段自动带出","disabled":""},model:{value:(_vm.bossSelectedItem.idCard),callback:function ($$v) {_vm.$set(_vm.bossSelectedItem, "idCard", $$v)},expression:"bossSelectedItem.idCard"}})],1),_c('el-form-item',{attrs:{"prop":"","label":"经销商编号"}},[_c('el-input',{attrs:{"placeholder":"新增后自动带出","disabled":"","value":!_vm.selection ? '' : _vm.selection.dealerCode}})],1),_c('el-form-item',{attrs:{"prop":"","label":"经销商名称"}},[_c('el-input',{attrs:{"placeholder":"新增后自动带出","disabled":"","value":!_vm.selection ? '' : _vm.selection.dealerName}})],1),_c('required-form-item-select',{ref:"authorizedCityRef",attrs:{"prop":"authorizedCityCode","label":"授权城市","placeholder":"请选择授权城市","labelKey":item => `${item.city}${item.area || ''}${item.town || ''}`,"valueKey":"authorizedCityCode","selectProps":{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        },"total-count":_vm.authorizedCityTotal,"getDataList":_vm.getCityInfoData,"getNextList":_vm.getCityInfoData},on:{"onOptionsListNotFound":_vm.onNotFoundCity,"changeSelectedItem":e => {
            _vm.authorizedCity = e
            _vm.form.authorizedCityName = `${e.city || ''}${e.area || ''}${
              e.town || ''
            }`
            _vm.form.marketLevelCode = e.marketLevelCode
            _vm.form.marketLevelName = e.marketLevelName
          }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(`${scope.row.city || ''}${scope.row.area || ''}${ scope.row.town || '' }`))])]}}]),model:{value:(_vm.form.authorizedCityCode),callback:function ($$v) {_vm.$set(_vm.form, "authorizedCityCode", $$v)},expression:"form.authorizedCityCode"}}),_c('required-module-form-item-select',{attrs:{"prop":"channelType","required":false,"label":"经销商类型","type":"dealer_type","network":""},model:{value:(_vm.form.channelType),callback:function ($$v) {_vm.$set(_vm.form, "channelType", $$v)},expression:"form.channelType"}}),_c('required-module-form-item-select',{ref:"secondaryChannelTypeRef",attrs:{"required":false,"type":"dealer_sub_type","label":"经销商类型二级分类"},on:{"change":() => {
            _vm.form.thirdChannelType = ''
          }},model:{value:(_vm.form.secondaryChannelType),callback:function ($$v) {_vm.$set(_vm.form, "secondaryChannelType", $$v)},expression:"form.secondaryChannelType"}}),_c('required-module-form-item-select',{ref:"thirdChannelTypeRef",attrs:{"required":false,"type":"dealer_thirdary_type","label":"经销商类型三级分类","parent-code":_vm.form.secondaryChannelType,"emptyDisabled":"","dependentParentCode":""},model:{value:(_vm.form.thirdChannelType),callback:function ($$v) {_vm.$set(_vm.form, "thirdChannelType", $$v)},expression:"form.thirdChannelType"}}),_c('required-module-form-item-select',{attrs:{"label":"经销商性质","required":false,"type":"join_nature"},model:{value:(_vm.form.joinNature),callback:function ($$v) {_vm.$set(_vm.form, "joinNature", $$v)},expression:"form.joinNature"}}),_c('el-form-item',{attrs:{"prop":"createTime","label":"首批摆场样品下单终审时间"}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"首批摆场样品下单终审时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.form.firstOpeningTime),callback:function ($$v) {_vm.$set(_vm.form, "firstOpeningTime", $$v)},expression:"form.firstOpeningTime"}})],1),_c('el-form-item',{attrs:{"prop":"createTime","label":"创建时间"}},[_c('el-date-picker',{attrs:{"disabled":"","type":"datetime","placeholder":"自动生成"},model:{value:(_vm.form.createTime),callback:function ($$v) {_vm.$set(_vm.form, "createTime", $$v)},expression:"form.createTime"}})],1),_c('el-form-item',{attrs:{"prop":"updateTime","label":"数据更新时间"}},[_c('el-date-picker',{attrs:{"disabled":"","type":"datetime","placeholder":"自动生成"},model:{value:(_vm.form.updateTime),callback:function ($$v) {_vm.$set(_vm.form, "updateTime", $$v)},expression:"form.updateTime"}})],1),_c('RequiredFormItemSelect',{attrs:{"label":"AB 商","prop":"dealerLevel","required":false,"dataList":[
          {
            title: 'A商',
            name: 'A'
          },
          {
            title: 'B商',
            name: 'B'
          }
        ],"valueKey":"name","labelKey":"title"},model:{value:(_vm.form.dealerLevel),callback:function ($$v) {_vm.$set(_vm.form, "dealerLevel", $$v)},expression:"form.dealerLevel"}}),_c('required-module-form-item-select',{attrs:{"type":"dealer_source","label":"经销商来源","prop":"source","required":false,"selectListeners":{
          change: () => {
            _vm.form.sourceClassification = ''
          }
        }},model:{value:(_vm.form.source),callback:function ($$v) {_vm.$set(_vm.form, "source", $$v)},expression:"form.source"}}),_c('required-module-form-item-select',{attrs:{"type":"dealer_sub_source","parentCode":_vm.form.source,"getDataTrigger":_vm.form.source ? ['change'] : [],"label":"经销商来源二级分类","required":false,"emptyDisabled":""},model:{value:(_vm.form.sourceClassification),callback:function ($$v) {_vm.$set(_vm.form, "sourceClassification", $$v)},expression:"form.sourceClassification"}}),_c('RequiredFormItem',{attrs:{"label":"状态","prop":"ifEnabled","required":false}},[_c('el-switch',{attrs:{"active-text":"启用","inactive-text":"停用","active-value":0,"inactive-value":1},model:{value:(_vm.form.ifEnabled),callback:function ($$v) {_vm.$set(_vm.form, "ifEnabled", $$v)},expression:"form.ifEnabled"}})],1)],1)],1),_c('div',{staticClass:"demo-drawer__footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }