<template>
  <div>
    <div ref="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`"
        >
          <template v-if="item.select">
            <el-select
              v-model="formInline[item.key]"
              placeholder="请选择"
              :filterable="item.search"
            >
              <el-option label="全部" value="" />
              <el-option
                v-for="select in item.options"
                :key="select.value"
                :label="select.label"
                :value="select.value"
              />
            </el-select>
          </template>
          <template v-else>
            <el-input
              v-model="formInline[item.key]"
              :placeholder="`请输入${item.label}`"
              @keyup.enter.native="query"
            />
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleDownload"
            v-loading.fullscreen.lock="fullscreenLoading"
            >导出实控人</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px)` }">
      <el-table
        ref="table"
        v-loading="loading"
        height="100%"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      >
        <el-table-column
          prop="bossNumber"
          label="实控人编码（账号）"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <el-link type="success" @click="handleDetail(scope.row)">{{
              scope.row.bossNumber
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="realName"
          label="实控人"
          width="170"
          align="center"
        />
        <el-table-column
          prop="contactInfo"
          label="联系方式"
          width="170"
          align="center"
        />
        <el-table-column prop="sex" label="性别" width="170" align="center">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <RequestModuleSelect
                :value="scope.row.sex == 0 ? '未知' : scope.row.sex"
                placeholder=""
                type="sex"
                disabled
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="170" align="center" />
        <el-table-column
          prop="maritalStatus"
          label="婚姻"
          width="170"
          align="center"
        >
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <RequestModuleSelect
                :value="scope.row.maritalStatus"
                placeholder=""
                type="marital_status"
                disabled
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="nativePlace"
          label="籍贯"
          width="170"
          align="center"
        />
        <el-table-column
          prop="ifEnabled"
          label="生效/失效"
          width="170"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.ifEnabled === 0 ? '生效' : '失效' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="创建时间"
          width="170"
          align="center"
        />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <div
                v-permission="['edit_boss_001']"
                class="handle"
                @click="showDialog('修改实控人信息', 'dealer', scope.row)"
              >
                修改实控人信息
              </div>
              <div class="handle" @click="handleDetail(scope.row)">
                查看详情
              </div>
              <div
                class="handle"
                @click="showDialog('相关流程', 'dealer_boss', scope.row)"
              >
                相关流程
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <DialogTable
      :id="id"
      :visible.sync="dialogVisible"
      :title="title"
      :data-list="tableList"
      :type="type"
      :get-data-list="getDataList"
    />
  </div>
</template>

<script>
import DialogTable from './components/dialogTable'
// import {
//   datadictionaryList
// } from '@/api/common'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import {
  getBossDataList,
  getBossDetail,
  bossExport,
  excelBossData
} from '@/api/businessOos'

import { tableHeight } from '@/mixin/tableHeight'
import { excelJsonToExcel } from './fn'
import { bossJsonValue } from './json'
const cloud_userInfo = JSON.parse(localStorage.getItem('cloud_userInfo'))
const formInlineData = [
  {
    label: '实控人编码',
    key: 'bossNumber',
    select: false
  },
  {
    label: '实控人姓名',
    key: 'realName',
    select: false
  }
]
export default {
  components: {
    DialogTable,
    RequestModuleSelect
  },
  mixins: [tableHeight],
  data() {
    return {
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      shopTypeOption: [],
      storeNatureOption: [],
      id: '',
      status: 0,
      loading: false,
      permList: cloud_userInfo.permList,
      fullscreenLoading: false
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async handleDownload() {
      this.fullscreenLoading = true
      try {
        const { data } = await excelBossData({ roleCode: 10 })
        excelJsonToExcel(bossJsonValue, data, '实控人列表')
      } catch (error) {
        this.$message.error('导出失败')
      } finally {
        this.fullscreenLoading = false
      }
      // bossExport({roleCode:10})
    },
    handleDetail(item) {
      window.open(
        `${
          process.env.NODE_ENV !== 'production'
            ? 'http://10.12.0.64:8083'
            : 'https://atom.musiyoujia.com'
        }/dealermanage/distributorpage/boss.html?bossNumber=${item.bossNumber}`,
        '_blank'
      )
    },
    // 获取门店列表
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })
      this.loading = true
      const res = await getBossDataList({
        ...this.formInline,
        roleCode: 10,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    query() {
      // console.log(this.formInline);
      this.page = 1
      this.getDataList()
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.id = data.id
      switch (type) {
        case 'dealer':
          this.getDealerDataList(data.id)
          break
      }
    },
    async getDealerDataList(id) {
      const res = await getBossDetail({
        id
      })
      // console.log(res)
      this.tableList = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';

.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
