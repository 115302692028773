<template>
  <div>
    <el-tabs
      v-if="tabs"
      v-model="activeName"
      type="card"
      @tab-click="handleClick">
      <el-tab-pane label="传统送达方" name="old"></el-tab-pane>
      <el-tab-pane label="新渠道送达方" name="new"></el-tab-pane>
    </el-tabs>
    <el-table
      ref="filterTable"
      border
      :data="dataList"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      style="width: 100%">
      <el-table-column
        prop="shop_brand_number"
        :label="`${activeName == 'new' ? '新渠道' : ''}送达方编号`" />
      <el-table-column
        prop="authorized_brand_name"
        :label="`${activeName == 'new' ? '新渠道' : ''}送达方名称`" />
      <el-table-column prop="authorized_city_name" label="授权城市" />
      <el-table-column prop="big_region_name" label="营销部" />
      <el-table-column prop="small_region_name" label="营销中心" />
      <el-table-column prop="market_level_name" label="授权城市级别" />
      <el-table-column prop="channel_cooperation_type" label="渠道合作类型">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.channel_cooperation_type === 10
                ? '加盟业务'
                : '新渠道业务'
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="market_level_type_name"
        label="授权城市级别二级分类">
      </el-table-column>
      <el-table-column prop="ab_status_explain" label="送达方状态">
        <template slot-scope="scope">
          <div class="shopTypeSelect">
            <span v-if="scope.row.ab_status_explain">
              <RequestModuleSelect
                :value="scope.row.ab_status_explain"
                type="receiver_type"
                disabled />
            </span>
            <span v-else>
              <RequestModuleSelect
                :value="scope.row.ab_if_enabled"
                type="ifEnabled_type"
                disabled />
              <!-- {{ scope.row.ifEnabled ? "已停用" : "启用中" }} -->
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="salesOrganizationCode" label="销售组织代码" />
      <el-table-column prop="salesOrganizationName" label="销售组织" /> -->
      <!-- <el-table-column prop="orderSpecialist" label="订单专员" /> -->
    </el-table>
  </div>
</template>

<script>
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
export default {
  components: {
    RequestModuleSelect
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    tabs: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeName: 'old'
    }
  },
  methods: {
    handleClick() {
      this.onChange(this.id, this.activeName)
    }
  }
}
</script>

<style lang="scss" scoped>
.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
