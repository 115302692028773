<template>
  <div>
    <div ref="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <request-module-select
            v-if="item.select"
            v-model="formInline[item.key]"
            :filterable="true"
            :placeholder="item.label"
            :type="item.model" />
          <el-input
            v-else
            v-model="formInline[item.key]"
            :placeholder="`请输入${item.label}`"
            @keyup.enter.native="query"></el-input>
        </el-form-item>
        <required-brand-form-item-select
          v-model="formInline.brandId"
          :required="false"
          label="授权品牌："
          :select-props="{
            filterable: true,
            remote: true,
            clearable: true,
            multiple: false
          }" />
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="editDealer(0)"
            v-permission="['business_add']"
            >新增经销商</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="editDealer(1)"
            v-permission="['business_update']"
            >修改经销商</el-button
          >
          <el-button
            type="primary"
            v-permission="['shop_excel']"
            :loading="fullscreenLoading"
            @click="downloadExcelSon"
            >导出经销商子系列</el-button
          >
          <BrandExport
            title="导出经销商总系列"
            v-permission="['shop_excel']"
            :loading="fullscreenLoading"
            @downloadExcel="downloadExcel" />
        </el-form-item>
      </el-form>
    </div>
    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px)` }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        height="100%"
        v-loading="loading"
        @select="selectRow"
        :show-overflow-tooltip="true">
        <el-table-column
          type="selection"
          width="50"
          align="center"
          fixed="left" />
        <el-table-column
          prop="dealerCode"
          label="经销商编号"
          width="120"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="dealerName"
          label="经销商名称"
          width="170"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="newOrTransfer"
          label="空白/转让加盟"
          width="110"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="ifEnabled"
          label="经销商状态"
          width="110"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.ifEnabled ? '停用' : '启用' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="bossNumber"
          label="实控人编号"
          width="120"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="contactInfo"
          label="实控人联系方式"
          width="120"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="bossName"
          label="实控人"
          width="120"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="historyBoss"
          label="历史实控人"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="province"
          label="省份"
          width="140"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="city"
          label="市"
          width="140"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="area"
          label="区"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="source"
          label="经销商来源"
          width="130"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                :value="scope.row.source"
                placeholder=""
                type="dealer_source"
                disabled />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sourceClassification"
          label="经销商来源二级分类"
          width="150"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                :value="scope.row.sourceClassification"
                placeholder=""
                type="dealer_sub_source"
                disabled />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="firstOpeningTime"
          label="首批摆场样品下单终审时间"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="createTime"
          label="授权时间"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="jdBrandTime"
          label="经典-授权时间"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column
          prop="jdCancelBrandTime"
          label="经典-取消授权时间"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column prop="dealerLevel" label="AB 商" width="130" />
        <el-table-column
          prop="jdDealerStatus"
          label="经典经销商状态"
          width="130" />
        <el-table-column
          prop="channel"
          label="经销商类型"
          width="200"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                v-model="scope.row.channel"
                placeholder=""
                valueKey="content"
                :oldValueKeys="['code']"
                type="cooperation_code"
                disabled />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="joinNature"
          label="经销商性质"
          width="200"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                :value="scope.row.joinNature"
                placeholder=""
                type="join_nature"
                disabled />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="shopCreateType"
          label="开店类型"
          width="120"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                :value="scope.row.shopCreateType"
                placeholder=""
                type="open_type"
                disabled />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="starRating"
          label="经销商4S星级"
          width="120"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          prop="number"
          label="授权品牌"
          width="140"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('授权品牌', 'brand', scope.row)">
                查看授权品牌
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="关联售达方" width="120">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('关联售达方', 'authorzed', scope.row)">
                查看售达方
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="关联送达方" width="120">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('关联送达方', 'boss', scope.row)">
                查看送达方
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="关联门店" width="100">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('门店列表', 'shop', scope.row)">
                查看门店
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link">
                查看更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="showDialog('授权品牌', 'brand', scope.row)"
                  >查看授权品牌</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="
                    showDialog('关联售达方', 'authorzed', scope.row)
                  "
                  >查看售达方</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="showDialog('关联送达方', 'boss', scope.row)"
                  >查看送达方</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="showDialog('门店列表', 'shop', scope.row)"
                  >查看门店</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <DialogTable
      ref="dialogTable"
      :visible.sync="dialogVisible"
      :title="title"
      :type="type"
      :dataList="tableList"
      :selection="selection"
      :onChange="handleChange"
      :id="id"
      :authorizedCity="authorizedCityName"
      @getDataList="getDataList"
      @clearSelection="clearSelection" />
  </div>
</template>

<script>
import DialogTable from './components/dialogTable'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import BrandExport from './components/brandExport'

import {
  getDealerDataList,
  getDealerBrand,
  getDealerShop,
  getDealerBoss,
  getDealerBusiness,
  getNewBusinessDeliver,
  getBossDetail,
  excelBoss,
  excelToDealer,
  getBossCorrelationInfo,
  exportCustomerSeries
} from '@/api/businessOos'

import { getStorage } from '@/utils/storage'

import { tableHeight } from '@/mixin/tableHeight'
import { excelJsonToExcel } from './fn'
import { dealerJsonValue } from './json'
const formInlineData = [
  {
    label: '经销商编号',
    key: 'dealerCode'
  },
  {
    label: '经销商名称',
    key: 'dealerName'
  },
  {
    label: '授权城市',
    key: 'authorizedCityName'
  },
  {
    label: '省份',
    key: 'province'
  },
  {
    label: '市区',
    key: 'city'
  },
  {
    label: '区/镇/县',
    key: 'area'
  },
  {
    label: '经销商状态',
    key: 'ifEnabled',
    select: true,
    model: 'dealer_ifEnabled_type'
  },
  {
    label: '城市等级',
    key: 'marketLevelName',
    select: true,
    model: 'city_level_name'
  }
]
export default {
  components: {
    DialogTable,
    RequestModuleSelect,
    RequiredBrandFormItemSelect,
    BrandExport
  },
  mixins: [tableHeight],
  data() {
    return {
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      id: '',
      status: 0,
      loading: false,
      tabsType: 'old',
      selection: null,
      userInfo: {},
      authorizedCityName: '',
      fullscreenLoading: false,
      userInfo: getStorage('userInfo') || {}
      // withoutTableHeight: 0
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    this.getDataList()
  },
  mounted() {
    // const formH = this.$refs.form.offsetHeight
    // const topH = document.querySelector('#topBar').offsetHeight
    // this.withoutTableHeight = formH + topH + 100
  },
  methods: {
    // 导出excel
    async downloadExcel(val) {
      this.fullscreenLoading = true
      // await excelBoss({
      //   // accountId:this.userInfo.accountId
      // })
      try {
        const res = await excelToDealer({
          accountId: this.userInfo.accountId,
          cityLevelBrand: val
        })
        res.data.forEach(item => {
          if (item.statusExplain > 40) {
            item.ifEnabled = 1
          } else {
            item.ifEnabled = 0
          }
        })
        excelJsonToExcel(dealerJsonValue, res.data, '经销商总系列')
      } catch (error) {
        this.$message.error('excel导出失败')
      } finally {
        this.fullscreenLoading = false
      }
    },
    //导出经销商子系列
    async downloadExcelSon(cityLevelBrand) {
      this.fullscreenLoading = true
      try {
        const tempArr = []
        const { totalPage, excelTitle, data } = await exportCustomerSeries({
          userId: this.userInfo.accountId,
          dataType: 'distributor',
          pageSize: 5000
        })
        tempArr.push(...data)
        if (totalPage > 1) {
          const list = Array.from({ length: totalPage - 1 }, (v, i) => i + 2)
          for await (const item of list) {
            const res = await exportCustomerSeries({
              userId: this.userInfo.accountId,
              dataType: 'distributor',
              currPage: item,
              pageSize: 5000
            })
            tempArr.push(...res.data)
          }
        }

        const json = Object.keys(excelTitle).map(item => ({
          title: excelTitle[item],
          prop: item
        }))

        excelJsonToExcel(json, tempArr, '经销商子系列')
        this.fullscreenLoading = false
      } catch (error) {
        this.fullscreenLoading = false
        this.$message.error('excel导出失败')
      } finally {
        this.fullscreenLoading = false
      }
    },
    clearSelection() {
      this.$refs.table.clearSelection()
      this.selection = null
    },
    editDealer(type) {
      if (type && !this.selection) {
        this.$message.error('请选择一条记录')
        return
      }
      this.dialogVisible = true
      this.title = type ? '修改经销商' : '新增经销商'
      if (!type) {
        this.$refs.table.clearSelection()
        this.selection = null
      }
      this.type = 'editDealer'
    },
    selectRow(selection, row) {
      // console.log('选中行',selection,row)
      this.$refs.table.clearSelection()
      this.$refs.table.toggleRowSelection(row)
      this.selection = Object.assign({}, row)
    },
    query() {
      // console.log(this.formInline);
      this.page = 1
      this.getDataList()
    },
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })
      this.loading = true
      const res = await getDealerDataList({
        ...this.formInline,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.id = data.businessLicenseId
      this.authorizedCityName = data.authorizedCityName
      switch (type) {
        case 'brand':
          //查看授权品牌
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'party'
          })
          break
        case 'shop':
          //查看授权门店
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'shop'
          })
          break
        case 'boss':
          //查看送达方
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'authorized_brand'
          })
          break
        case 'authorzed':
          //查看售达方
          this.getBossCorrelationInfo({
            crmDistributorId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'license'
          })
          break
        case 'relevant':
          this.getRelevantList(data)
          break
      }
    },
    getBossCorrelationInfo(params) {
      getBossCorrelationInfo(params).then(res => {
        this.tableList = res.data
      })
    },
    // 获取授权品牌
    async getBrandData(data) {
      const res = await getDealerBrand({
        // businessLicenseId: data.businessLicenseId,
        // authorizedCityCode: data.authorizedCityCode,
        dealerCode: data.dealerCode,
        ifEnabled: -1
      })
      this.tableList = res.data
    },
    // 获取门店
    async getShopDataList(data) {
      const res = await getDealerShop({
        // businessLicenseId: data.businessLicenseId,
        // cityCode: data.authorizedCityCode
        bossId: data.bossId,
        authorizedCityCode: data.authorizedCityCode
      })
      this.tableList = res.data
    },
    // // 获取送达方
    async getBossList(data, type = 'old') {
      // const res = await getDealerBoss({
      //   businessLicenseId: data.businessLicenseId,
      //   authorizedCityCode: data.authorizedCityCode,
      //   status
      // })
      // this.tableList = res.data
      this.tabsType = type
      if (type == 'old') {
        // console.log(type);
        const res = await getDealerBoss({
          // businessLicenseId: data.businessLicenseId,
          bossId: data.bossId,
          authorizedCityCode: data.authorizedCityCode
        })
        this.tableList = res.data.map(item => {
          return {
            authorizedName: item.authorizedBrandName,
            ...item
          }
        })
      } else {
        const res = await getNewBusinessDeliver({
          businessLicenseId: data.businessLicenseId
        })
        console.log(res)
        this.tableList = res.data.map(item => {
          return {
            authorizedName: item.authorizedBrandName,
            ...item
          }
        })
      }
    },
    // // 获取售达方
    async getDealerList(data) {
      const res = await getDealerBusiness({
        bossId: data.bossId,
        authorizedCityCode: data.authorizedCityCode,
        shopStatus: -1
      })
      this.tableList = res.data.map(item => {
        return {
          realName: item.bossName,
          dealerCode: item.licenseNumber,
          ...item
        }
      })
    },
    // 获取相关流程
    getRelevantList(data) {
      console.log(data)
    },
    handleChange(id, type) {
      const data = this.dataList.find(item => item.businessLicenseId == id)
      this.getBossList(data, type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';
::v-deep .el-table__fixed-header-wrapper .el-checkbox {
  display: none;
}
.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
