<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="visibleDialog"
      direction="rtl"
      :size="`${widthStyle};overflow-y:auto`"
      v-bind="$attrs"
      v-on="$listeners">
      <component
        :is="roleComponent"
        :dataList="dataList"
        :rowData="rowData"
        :cancel="cancel"
        v-if="roleComponent && visibleDialog"
        v-bind="$attrs"
        :type="type"
        v-on="$listeners"></component>
    </el-drawer>
  </div>
</template>

<script>
import BusinessTable from './businessTable'
import AuthorzedTable from './authorzedTable'
import BossTable from './bossTable'
import ShopTable from './shopTable'
import BrandTable from './brandTable'
import CityTable from './cityTable'
import shopDetail from './shopDetail'
import codeTable from './codeTable'
import orderTable from './orderTable'
import editBusiness from './editBusiness'
import dealerTable from './dealerTable'
import editShopBrandTable from './editShopBrandTable'
import editDealer from './editDealer'
import editAutorzedBrandTable from './editAutorzedBrandTable'
import businessDetail from './businessDetail'
import relevantTable from './relevantTable.vue'
import brandAuthorization from './brandAuthorization.vue'
import shopAuthorizationBrandTable from './shopAuthorizationBrandTable.vue'
export default {
  components: {
    BusinessTable,
    AuthorzedTable,
    BossTable,
    ShopTable,
    BrandTable,
    CityTable,
    shopDetail,
    codeTable,
    orderTable,
    editBusiness,
    dealerTable,
    editShopBrandTable,
    editDealer,
    editAutorzedBrandTable,
    businessDetail,
    relevantTable,
    brandAuthorization,
    shopAuthorizationBrandTable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    },
    rowData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    visibleDialog: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    type: {
      handler(value) {
        // console.log(value);
        // this.formDataList = value
        this.roleComponent = this.roleComponents[value]
        this.role = value
        // if(value==='editDealer'){
        //   this.widthStyle = '80%'
        // }
      }
    }
  },
  data() {
    return {
      widthStyle: '50%',
      formDataList: [],
      roleComponents: {
        business: BusinessTable,
        authorzed: AuthorzedTable,
        boss: BossTable,
        shop: ShopTable,
        brand: BrandTable,
        city: CityTable,
        shopDetail: shopDetail,
        code: codeTable,
        order: orderTable,
        editBusiness: editBusiness,
        dealer: dealerTable,
        editShopBrand: editShopBrandTable,
        editDealer,
        editAutorzedBrand: editAutorzedBrandTable,
        businessDetail,
        dealer_business_license: relevantTable,
        dealer_shop: relevantTable,
        dealer_boss: relevantTable,
        dealer_authorized_brand: relevantTable,
        brandAuthorization,
        shopBrand: shopAuthorizationBrandTable
      },
      roleComponent: null,
      role: null
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  mounted() {
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 1200) {
      this.widthStyle = '100%'
    }
  },
  methods: {
    cancel() {
      this.visibleDialog = false
    },
    resizeHandler() {
      if (document.body.clientWidth < 1200) {
        this.widthStyle = '100%'
      } else {
        this.widthStyle = '50%'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
