var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("新增")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.del}},[_vm._v("批量失效")])],1),_c('el-table',{ref:"filterTable",staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.dataList,"header-cell-style":{ background: '#F4F5F8', color: '#393F4D' }},on:{"row-click":_vm.rowClick,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"brandName","label":"授权系列"}}),_c('el-table-column',{attrs:{"prop":"authorizedCityName","label":"授权城市"}}),_c('el-table-column',{attrs:{"prop":"ifEnabled","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.ifEnabled === 1 ? '失效' : '启用中')+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"sort","label":"操作","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.edit(scope.row.id)}}},[_vm._v(" 修改 ")])],1)]}}])})],1),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"375px","append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('required-form-item-select',{attrs:{"dataList":_vm.authorizedBrand,"prop":"brandId","label":"授权系列","labelKey":"name","valueKey":"id","selectProps":{
          disabled: false,
          clearable: true,
          filterable: true
        }},on:{"changeSelectedItem":value => {
            if (value) {
              _vm.form.bigBrandId = value.parentId
              _vm.form.brandCode = value.code
            }
          }},model:{value:(_vm.form.brandId),callback:function ($$v) {_vm.$set(_vm.form, "brandId", $$v)},expression:"form.brandId"}}),_c('required-form-item-select',{ref:"authorizedCityRef",attrs:{"dataList":_vm.cityList,"prop":"crmDistributorCode","getDataList":_vm.getAsyncCityList,"getNextList":_vm.getAsyncCityList,"totalCount":_vm.totalCount,"label":"经销商","labelKey":"dealerName","valueKey":"dealerCode","selectProps":{
          remote: true,
          'popper-class': 'custom-business-select',
          filterable: true,
        }},on:{"changeSelectedItem":value => {
            _vm.getDealerList(value)
          },"onOptionsListNotFound":_vm.onOptionsListNotFound},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.dealerName))]),_c('small',[_vm._v("("+_vm._s(scope.row.dealerCode)+")")])]}}]),model:{value:(_vm.form.crmDistributorCode),callback:function ($$v) {_vm.$set(_vm.form, "crmDistributorCode", $$v)},expression:"form.crmDistributorCode"}}),_c('el-form-item',{attrs:{"label":"合作类型"}},[_c('el-select',{attrs:{"placeholder":"请选择送达方渠道合作类型"},model:{value:(_vm.channelCooperationType),callback:function ($$v) {_vm.channelCooperationType=$$v},expression:"channelCooperationType"}},[_c('el-option',{attrs:{"label":"加盟业务","value":10}}),_c('el-option',{attrs:{"label":"新渠道业务","value":20}})],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addBrand}},[_vm._v("保 存")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }