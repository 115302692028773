<template>
  <div class="subsidiesSetting">
    <TablePro
      ref="tableProRef"
      :actions="['export']"
      :columns="columns"
      :request="requestList">
    </TablePro>
  </div>
</template>

<script>
import TablePro, { render } from '@/components/TablePro'
import { newchannelnetworkGetNetWorkPage } from '@/api/businessOos/'
import { getStorage } from '@/utils/storage'

export default {
  components: {
    TablePro
  },
  data() {
    const columns = [
      {
        title: '网点编号',
        name: 'networkNumber',
        search: true
      },
      { title: '网点名称', name: 'networkName' },
      {
        title: '网点状态',
        name: 'status',
        search: true,
        valueType: 'select',
        valueEnum: [
          { label: '生效', value: 1 },
          { label: '失效', value: 0 }
        ]
      },
      {
        title: '经销商编号',
        hideInTable: true,
        name: 'dealerCode',
        search: true
      },
      { title: '合作方营业执照全称', name: 'partnerBusinessLicenseName' },
      {
        title: '合作渠道简称',
        name: 'partnerChannelShortName',
        search: true
      },
      { title: '渠道类型', name: 'channelName' },
      { title: '授权城市', name: 'authCityName' },
      { title: '合作网点所在详细地址 ', name: 'networkDetailAddress' },
      { title: '合作模式', name: 'cooperationModeName' },
      { title: '网点面积', name: 'networkArea' },
      { title: '合作开始日期', name: 'cooperationStartTime' },
      {
        title: '是否网点设计',
        name: 'designNetwork',
        valueType: 'select',
        valueEnum: [
          { label: '是', value: 1 },
          { label: '否', value: 0 }
        ]
      },
      {
        title: '是否上样',
        name: 'sampleFlag',
        valueType: 'select',
        valueEnum: [
          { label: '是', value: 1 },
          { label: '否', value: 0 }
        ]
      },
      { title: '省', name: 'province', search: true },
      { title: '市', name: 'city', search: true },
      { title: '区/县', name: 'area', search: true },
      { title: '关联售达方', name: 'businessLicenseName' },
      { title: '关联送达方', name: 'senderName' },
      { title: '关联经销商', name: 'dealerName' }
    ]

    return {
      columns
    }
  },
  methods: {
    requestList(params) {
      const userInfo = getStorage('userInfo') || {}
      return newchannelnetworkGetNetWorkPage({
        ...params,
        userId: userInfo.accountId
      })
    }
  }
}
</script>
