<template>
  <el-table
    ref="filterTable"
    border
    :data="dataList"
    :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
    style="width: 100%">
    <el-table-column prop="shop_number" label="门店编号" />
    <el-table-column prop="shop_name" label="门店名称" />
    <el-table-column prop="s_status_explain" label="门店状态">
      <template slot-scope="scope">
        <div class="shopTypeSelect">
          <request-module-select
            :value="scope.row.s_status_explain"
            type="shop_state_code"
            :disabled="true" />
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="contact_person" label="门店联系人" />
    <el-table-column prop="contact_person_idcard" label="联系人身份证" />
    <el-table-column prop="contact_person_phone" label="联系人电话" />
  </el-table>
</template>

<script>
// 10待装修20装修中30装修完成40正常营业50装修失效60撤店失效
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
export default {
  components: {
    RequestModuleSelect
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
