<template>
  <el-table
    ref="filterTable"
    border
    :data="dataList"
    :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
    style="width: 100%">
    <el-table-column prop="license_number" label="售达方编号" />
    <el-table-column prop="business_license_name" label="售达方名称" />
    <el-table-column prop="bl_if_enabled" label="售达方状态">
      <template #default="{ row }">
        <div>
          <span v-if="row.bl_if_enabled === 0">生效</span>
          <span v-else-if="1">失效</span>
          <span v-else></span>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="boss_name" label="实控人" />
    <el-table-column prop="legal_person_name" label="法人" />
  </el-table>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped></style>
