<template>
  <div>
    <div style="margin: 20px">
      <el-button type="primary" @click="add">新增</el-button>
      <el-button type="danger" @click="del">批量失效</el-button>
    </div>
    <el-table
      ref="filterTable"
      border
      :data="dataList"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      style="width: 100%"
      @row-click="rowClick"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="name" label="授权系列" />
      <el-table-column prop="brandArea" label="品牌门店面积" />
      <el-table-column prop="sampleSets" label="品牌样品套数" />
      <el-table-column prop="authorizedTime" label="门店系列授权时间" />
      <el-table-column prop="overTime" label="门店系列取消时间" />
      <el-table-column prop="sort" label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div @click.stop="">
            <el-button
              v-if="scope.row.ifEnabled === 1"
              @click="onWorking(scope.row)"
              >生效</el-button
            >
            <el-button type="success" @click="edit(scope.row.id)">
              修改
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="450px" append-to-body>
      <el-form ref="form" :model="form" label-width="150px">
        <!-- <el-form-item label="门店系列"> -->
        <required-form-item-select
          :dataList="authorizedBrand"
          v-model="form.code"
          label="门店系列"
          prop="code"
          labelKey="name"
          valueKey="code"
          :selectProps="{
            disabled: false,
            clearable: true,
            filterable: true
          }"
          @changeSelectedItem="
            value => {
              getBrandData(value)
            }
          " />
        <!-- </el-form-item> -->
        <!-- <div v-if="defaultType == 'shop'"> -->
        <!-- <el-form-item label="售达方"> -->
        <required-form-item-select
          label="售达方"
          prop="businessLicenseId"
          :dataList="authorzedList"
          v-model="form.businessLicenseId"
          labelKey="businessLicenseName"
          valueKey="id"
          :selectProps="{
            disabled: false,
            clearable: true
          }">
          <template v-slot="{ scope }">
            <span>{{ scope.row.licenseNumber }}</span> --
            <small>{{ scope.row.businessLicenseName }}</small>
          </template>
        </required-form-item-select>
        <!-- </el-form-item> -->
        <el-form-item label="品牌面积">
          <el-input
            v-model.trim="form.brandArea"
            type="number"
            placeholder="数字，<9999"></el-input>

          <!-- <FormInput
          label="品牌面积"
          prop="brandArea"
          :required='false'
          v-model="form.brandArea"
          placeholder="数字，<9999"
          :inputProps="{ type: 'number' }"
        /> -->
        </el-form-item>
        <el-form-item label="样品套数">
          <el-input
            v-model.trim="form.sampleSets"
            type="number"
            placeholder="数字，<9999"></el-input>
          <!-- <FormInput
          label="样品套数"
          prop="sampleSets"
          :required='false'
          v-model="form.sampleSets"
          placeholder="数字，<9999"
          :inputProps="{ type: 'number' }"
        /> -->
        </el-form-item>
        <!-- </div> -->
        <el-form-item label="门店系列授权时间">
          <el-date-picker
            v-model="form.authorizedTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="date"
            placeholder="请选择门店系列授权时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="门店系列取消时间">
          <el-date-picker
            v-model="form.overTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="date"
            placeholder="请选择门店系列取消时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addBrand">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FormInput from '@/components/CustomFormItem/requiredFormItemInput'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import {
  getEasyBusinessDataList,
  getShopBrand,
  requestUpdateBrand
} from '@/api/businessOos'
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    getEditShopBrand: {
      type: Function,
      default: () => {}
    },
    businessLicenseId: {
      type: String,
      default: ''
    }
  },
  components: {
    FormInput,
    RequiredFormItemSelect
  },
  data() {
    return {
      rowId: [],
      dialogVisible: false,
      form: {
        brandArea: 0,
        sampleSets: 0
      },
      defaultType: '',
      hiddenBtn: false,
      authorizedBrand: [], //系列列表数据
      authorzedList: [] //售达方列表
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(value) {
        if (value) {
          this.hiddenBtn = value.includes('新增')
        }
      }
    },
    id: {
      immediate: true,
      handler(value) {
        if (value) {
          // this.getAuthorzedList(value)
          this.getShopBrandData(value)
        }
      }
    },
    businessLicenseId(val) {
      console.log(val)
    }
  },
  created() {
    this.defaultType = this.$route.name == 'ShopList' ? 'shop' : 'authorzed'
  },
  methods: {
    async onWorking(data) {
      await requestUpdateBrand({
        ...data,
        ifEnabled: 0,
        statusExplain: 40
      })
      data.ifEnabled = 0
      data.statusExplain = 40
      this.$message.success('操作成功！')
    },
    // 获取门店列表系列
    async getShopBrandData() {
      const res = await getShopBrand({ shopId: this.id })
      this.authorizedBrand = res.data
    },
    // 选择门店系列
    getBrandData(value) {
      if (value) {
        this.form.code = value.code
      }
    },
    // 获取售达方
    async getAuthorzedList(id) {
      // console.log(this.businessLicenseId);
      // if(!this.businessLicenseId) return
      const res = await getEasyBusinessDataList({
        ids: id || null
      })
      this.authorzedList = res.data
    },
    edit(id) {
      // if (this.authorzedList.length == 0) {
      //   this.$message.warning('该门店没有售达方，无法新增/修改门店系列')
      //   return
      // }
      this.dialogVisible = true
      const data = this.dataList.find(item => item.id == id)
      this.form = data
      this.getAuthorzedList(this.form.businessLicenseId)
    },
    add() {
      // if (this.authorzedList.length == 0) {
      //   this.$message.warning('该门店没有售达方，无法新增/修改门店系列')
      //   return
      // }

      for (let key in this.form) {
        this.form[key] = ''
      }

      this.dialogVisible = true
      let ids = this.dataList.map(item => item.businessLicenseId)
      ids = Array.from(new Set(ids))
      this.getAuthorzedList(ids.join(','))
    },
    // 保存
    addBrand() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.shopId = this.id
          this.form.brandArea = Number(this.form.brandArea) || 0
          this.form.sampleSets = this.form.sampleSets || 0
          this.$emit('onSave', this.form)
          this.dialogVisible = false
        } else {
          this.$message({
            type: 'warning',
            message: '必填项不能为空'
          })
          return false
        }
      })
    },
    del() {
      if (this.rowId.length == 0) {
        this.$message.warning('请选择需要失效的门店系列')
        return
      }
      this.$confirm('确定失效?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('onDelete', this.rowId)
      })
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    }
  }
}
</script>

<style lang="scss" scoped></style>
