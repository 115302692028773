<template>
  <div class="container">
    <el-form :inline="true" :model="formData" label-position="top">
      <h3 style="padding-bottom: 20px">门店信息</h3>
      <div class="shopTypeSelect">
        <el-form-item label="门店名称">
          <el-input
            v-model="formData.shopName"
            :title="formData.shopName"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店简称">
          <el-input
            v-model="formData.shopAlias"
            :title="formData.shopAlias"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="省份">
          <el-input
            v-model="formData.province"
            :title="formData.province"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input
            v-model="formData.city"
            :title="formData.city"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <el-input
            v-model="formData.area"
            :title="formData.area"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="街道">
          <el-input
            v-model="formData.street"
            :title="formData.street"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店位置等级">
          <RequestModuleSelect
            v-model="formData.shopPositionLevel"
            label="门店位置等级"
            type="shop_level"
            :showLabel="false"
            disabled
            :title="formData.shopPositionLevel" />
        </el-form-item>
        <el-form-item label="门店楼层">
          <el-input
            v-model="formData.shopFloor"
            :title="formData.shopFloor"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店铺号">
          <el-input
            v-model="formData.shopFloorNumber"
            :title="formData.shopFloorNumber"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店经度">
          <el-input
            v-model="formData.longitude"
            :title="formData.longitude"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店纬度">
          <el-input
            v-model="formData.latitude"
            :title="formData.latitude"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店租金">
          <el-input
            v-model="formData.shopRent"
            :title="formData.shopRent"
            disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="门店星级">
          <el-input
            v-model="formData.shopStarLevel"
            :title="formData.shopStarLevel"
            disabled
          ></el-input>
        </el-form-item> -->
        <el-form-item label="计划开业时间">
          <!-- <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formData.openingTime"
            type="date"
            placeholder="选择日期"
            disabled
          >
          </el-date-picker> -->
          <el-input
            v-model="formData.planOpeningTime"
            :title="formData.planOpeningTime"
            readonly></el-input>
        </el-form-item>
        <el-form-item label="开业时间">
          <!-- <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formData.openingTime"
            type="date"
            placeholder="选择日期"
            disabled
          >
          </el-date-picker> -->
          <el-input
            v-model="formData.openingTime"
            :title="formData.openingTime"
            readonly></el-input>
        </el-form-item>
        <el-form-item label="最新装修时间">
          <!-- <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formData.decorationTime"
            type="date"
            placeholder="选择日期"
            disabled
          >
          </el-date-picker> -->
          <el-input
            v-model="formData.decorationTime"
            :title="formData.decorationTime"
            readonly></el-input>
        </el-form-item>
        <el-form-item label="经营模式">
          <RequestModuleSelect
            v-model="formData.managementModel"
            :showLabel="false"
            label="经营模式"
            type="management_model"
            disabled />
        </el-form-item>
        <el-form-item label="联系人" v-if="formData.managementModel !== '10'">
          <el-input
            v-model="formData.contactPerson"
            :title="formData.contactPerson"
            readonly></el-input>
        </el-form-item>
        <el-form-item
          label="联系人身份证"
          v-if="formData.managementModel !== '10'">
          <el-input
            v-model="formData.contactPersonIdcard"
            :title="formData.contactPersonIdcard"
            disabled></el-input>
        </el-form-item>
        <el-form-item
          label="联系人电话"
          v-if="formData.managementModel !== '10'">
          <el-input
            v-model="formData.contactPersonPhone"
            :title="formData.contactPersonPhone"
            disabled></el-input>
        </el-form-item>
        <el-form-item label="门店状态">
          <el-select
            v-model="formData.shopStatus"
            placeholder="请选择门店状态"
            disabled>
            <el-option label="生效" :value="0"> </el-option>
            <el-option label="失效" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <RequiredModuleFormItemSelect
            :required="false"
            v-model="formData.statusExplain"
            prop="statusExplain"
            type="shop_state_code"
            class="formItem"
            label="门店状态说明"
            :disabled="true" />
        <el-form-item label="实际撤店时间">
          <!-- <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="formData.actualWithdrawTime"
            type="date"
            placeholder="选择撤店时间"
            disabled
          >
          </el-date-picker> -->
          <el-input
            v-model="formData.actualWithdrawTime"
            :title="formData.actualWithdrawTime"
            readonly></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="门店性质">
          <!-- <el-input v-model="formData.storeNature" readonly></el-input> -->
          <!-- <el-select class="shopTypeSelect" v-model="formData.storeNature" disabled filterable remote>
            <el-option v-for="item in shopNatureOption" :key="item.code" :label="item.content" :value="item.code">
            </el-option>
          </el-select> -->
          <RequestModuleSelect
            :value="formData.storeNature"
            type="shop_nature_code"
            disabled />
        </el-form-item>
        <el-form-item class="formItem" label="撤店原因">
          <!-- <el-input v-model="formData.storeNature" readonly></el-input> -->
          <!-- <el-select class="shopTypeSelect" v-model="formData.storeNature" disabled filterable remote>
            <el-option v-for="item in shopNatureOption" :key="item.code" :label="item.content" :value="item.code">
            </el-option>
          </el-select> -->
          <RequestModuleSelect
            :value="formData.actualWithdrawReason"
            type="shop_remove_code"
            disabled />
        </el-form-item>
        <el-form-item class="formItem" label="渠道类型">
          <!-- <el-select class="shopTypeSelect" v-model="formData.partnership" disabled filterable remote>
            <el-option v-for="item in partnerShipOption" :key="item.code" :label="item.content" :value="item.code">
            </el-option>
          </el-select> -->
          <RequestModuleSelect
            :value="formData.channel"
            placeholder=""
            type="cooperation_code"
            disabled />
        </el-form-item>
        <el-form-item class="formItem" label="渠道性质">
          <!-- <el-input v-model="formData.shopType" readonly></el-input> -->
          <!-- <el-select class="shopTypeSelect" v-model="formData.shopType" disabled filterable remote>
            <el-option v-for="item in storeTypeOPtion" :key="item.code" :label="item.content" :value="item.code">
            </el-option>
          </el-select> -->
          <RequestModuleSelect
            :value="formData.shopType"
            type="store_type"
            disabled />
        </el-form-item>
        <el-form-item class="formItem" label="渠道性质二级分类（商场名称）">
          <el-input
            v-model="formData.shopTypeValue"
            readonly
            :title="formData.shopTypeValue"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="门店地址">
          <el-input
            :value="`${formData.province || ''}${formData.city || ''}${
              formData.area || ''
            }${formData.street || ''}${formData.shopTypeValue || ''}${
              formData.shopFloor || ''
            }${formData.shopFloorNumber || ''}`"
            readonly
            :title="`${formData.province || ''}${formData.city || ''}${
              formData.area || ''
            }${formData.street || ''}${formData.shopTypeValue || ''}${
              formData.shopFloor || ''
            }${formData.shopFloorNumber || ''}`"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="门店面积（㎡）">
          <el-input
            v-model="formData.shopArea"
            readonly
            :title="formData.shopArea"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="门头面积（㎡）">
          <el-input
            v-model="formData.doorHeaderArea"
            readonly
            :title="formData.doorHeaderArea"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="门店部分装修面积总和">
          <el-input
            v-model="formData.decorationArea"
            readonly
            :title="formData.decorationArea"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="独立外立面门头">
          <RequestModuleSelect
            :value="formData.independentDoorHeadFlag"
            type="independent_door_head_flag_boolean"
            disabled />
        </el-form-item>
        <el-form-item class="formItem" label="二级分销商">
          <el-input
            v-model="formData.contactPerson"
            readonly
            :title="formData.contactPerson"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="二级分销商身份证">
          <el-input
            v-model="formData.contactPersonIdcard"
            readonly
            :title="formData.contactPersonIdcard"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="二级分销商联系方式">
          <el-input
            v-model="formData.contactPersonPhone"
            readonly
            :title="formData.contactPersonPhone"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="是否有儿童配套专区">
          <el-input
            :value="formData.childrenSupportingArea > 0 ? '是' : '否'"
            readonly></el-input>
        </el-form-item>
        <el-form-item
          v-if="formData.childrenSupportingArea > 0"
          class="formItem"
          label="儿童配套专区面积">
          <el-input
            v-model="formData.childrenSupportingArea"
            readonly
            :title="formData.childrenSupportingArea"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-button type="primary" @click="openDialog">门店历史租金</el-button>
    <el-dialog
      title="门店历史租金"
      :visible.sync="shopRentTableVisible"
      append-to-body
      width="375px">
      <el-table :data="shopTableData">
        <el-table-column
          property="rent"
          label="门店租金(万元/年)"></el-table-column>
        <el-table-column
          property="createTime"
          label="历史时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { datadictionaryList } from '@/api/common'
import { shopRent } from '@/api/businessOos'
import data from '@/components/CustomFormItem/data/module.data'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
export default {
  components: {
    RequestModuleSelect,
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect,
    RequiredFormItemInput
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formData: {},
      shopNatureOption: [],
      storeTypeOPtion: [],
      partnerShipOption: [],
      marketOption: [],
      shopRentTableVisible: false,
      shopTableData: []
    }
  },
  watch: {
    // dataList(val) {
    //   console.log(val);
    //   this.formData = val[0]
    // }
    dataList: {
      deep: true,
      immediate: true,
      handler(value) {
        this.formData = value[0]
      }
    }
  },
  created() {
    // const typeList = ['shop_nature_code', 'partnership', 'store_type', 'market']
    // typeList.forEach(item => {
    //   this.getShopType(item)
    // })
  },
  methods: {
    // 获取门店类型选项
    async getShopType(type) {
      console.log(type)
      switch (type) {
        case 'shop_nature_code':
        case 'store_type':
        case 'market':
          const res = await datadictionaryList({
            type: type,
            module: 'distributor',
            currPage: 1,
            pageSize: 1000
          })
          type == 'shop_nature_code' && (this.shopNatureOption = res.data)
          type == 'store_type' && (this.storeTypeOPtion = res.data)
          type == 'market' && (this.marketOption = res.data)
          // console.log(res);
          break
        case 'partnership':
          this.partnerShipOption = data.partnership
          break
      }
    },
    // 打开门店租金列表
    async openDialog() {
      this.shopRentTableVisible = true
      const res = await shopRent({
        shop_id: this.formData.id
      })
      // console.log(res);
      this.shopTableData = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.formItem {
  width: 20%;
}

.container {
  padding: 0 20px;
}

.shopTypeSelect {
  ::v-deep {
    // .el-input.is-disabled,
    // .el-input {
    //   .el-input__inner {
    //     background-color: transparent;
    //     border-color: transparent;
    //     border: 1px solid #dfe4ed;
    //     color: #333;
    //     cursor: default;
    //     padding-left: 10px;
    //   }
    // }

    .el-form-item--small.el-form-item {
      width: 200px;
      margin-right: 0;
      margin-left: -1px;
      margin-top: -1px;
      border: 1px solid #dfe4ed;
    }

    .el-form-item__content {
      width: 100%;
      padding: 10px;
    }

    .el-form-item--small .el-form-item__label {
      width: 100%;
      border-bottom: 1px solid #dfe4ed;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #e2f2fb;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .el-input__icon {
      cursor: default;
    }
  }
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
