<template>
  <div style="padding: 0 20px" :loading="loading">
    <div class="demo-drawer__content">
      <el-form label-position="top" :model="form" ref="form">
        <required-form-item-select
          ref="bossRef"
          prop="bossId"
          v-model="form.bossId"
          label="实控人编号"
          placeholder="选择实控人（支持编号/姓名搜索）"
          labelKey="realName"
          valueKey="id"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :total-count="total"
          :getDataList="getBossDataList"
          :getNextList="getBossDataList"
          @onOptionsListNotFound="onNotFoundBoss"
          @changeSelectedItem="e => (bossSelectedItem = e)">
          <template v-slot="{ scope }">
            <span>{{ scope.row.realName }}（{{ scope.row.bossNumber }}）</span>
          </template>
        </required-form-item-select>
        <el-form-item prop="" label="实控人">
          <el-input
            placeholder="根据实控人编号字段自动带出"
            disabled
            v-model="bossSelectedItem.bossNumber" />
        </el-form-item>
        <el-form-item prop="" label="实控人身份证">
          <el-input
            placeholder="根据实控人编号字段自动带出"
            disabled
            v-model="bossSelectedItem.idCard" />
        </el-form-item>

        <el-form-item prop="" label="经销商编号">
          <el-input
            placeholder="新增后自动带出"
            disabled
            :value="!selection ? '' : selection.dealerCode" />
        </el-form-item>

        <el-form-item prop="" label="经销商名称">
          <el-input
            placeholder="新增后自动带出"
            disabled
            :value="!selection ? '' : selection.dealerName" />
        </el-form-item>

        <required-form-item-select
          ref="authorizedCityRef"
          prop="authorizedCityCode"
          v-model="form.authorizedCityCode"
          label="授权城市"
          placeholder="请选择授权城市"
          :labelKey="item => `${item.city}${item.area || ''}${item.town || ''}`"
          valueKey="authorizedCityCode"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :total-count="authorizedCityTotal"
          :getDataList="getCityInfoData"
          :getNextList="getCityInfoData"
          @onOptionsListNotFound="onNotFoundCity"
          @changeSelectedItem="
            e => {
              authorizedCity = e
              form.authorizedCityName = `${e.city || ''}${e.area || ''}${
                e.town || ''
              }`
              form.marketLevelCode = e.marketLevelCode
              form.marketLevelName = e.marketLevelName
            }
          ">
          <template v-slot="{ scope }">
            <span>{{
              `${scope.row.city || ''}${scope.row.area || ''}${
                scope.row.town || ''
              }`
            }}</span>
          </template>
        </required-form-item-select>

        <required-module-form-item-select
          prop="channelType"
          :required="false"
          v-model="form.channelType"
          label="经销商类型"
          type="dealer_type"
          network />

        <!-- prop="dealerCrmDistributor.secondaryChannelType" -->
        <required-module-form-item-select
          ref="secondaryChannelTypeRef"
          :required="false"
          v-model="form.secondaryChannelType"
          type="dealer_sub_type"
          label="经销商类型二级分类"
          @change="
            () => {
              form.thirdChannelType = ''
            }
          " />

        <!-- prop="dealerCrmDistributor.thirdChannelType" -->
        <required-module-form-item-select
          ref="thirdChannelTypeRef"
          :required="false"
          v-model="form.thirdChannelType"
          type="dealer_thirdary_type"
          label="经销商类型三级分类"
          :parent-code="form.secondaryChannelType"
          emptyDisabled
          dependentParentCode />
        <required-module-form-item-select
          v-model="form.joinNature"
          label="经销商性质"
          :required="false"
          type="join_nature" />
        <el-form-item prop="createTime" label="首批摆场样品下单终审时间">
          <el-date-picker
            v-model="form.firstOpeningTime"
            type="datetime"
            placeholder="首批摆场样品下单终审时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="createTime" label="创建时间">
          <el-date-picker
            disabled
            v-model="form.createTime"
            type="datetime"
            placeholder="自动生成">
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="updateTime" label="数据更新时间">
          <el-date-picker
            disabled
            v-model="form.updateTime"
            type="datetime"
            placeholder="自动生成">
          </el-date-picker>
        </el-form-item>
        <RequiredFormItemSelect
          label="AB 商"
          prop="dealerLevel"
          v-model="form.dealerLevel"
          :required="false"
          :dataList="[
            {
              title: 'A商',
              name: 'A'
            },
            {
              title: 'B商',
              name: 'B'
            }
          ]"
          valueKey="name"
          labelKey="title" />
        <required-module-form-item-select
          type="dealer_source"
          v-model="form.source"
          label="经销商来源"
          prop="source"
          :required="false"
          :selectListeners="{
            change: () => {
              form.sourceClassification = ''
            }
          }" />

        <required-module-form-item-select
          type="dealer_sub_source"
          v-model="form.sourceClassification"
          :parentCode="form.source"
          :getDataTrigger="form.source ? ['change'] : []"
          label="经销商来源二级分类"
          :required="false"
          emptyDisabled />

        <RequiredFormItem label="状态" prop="ifEnabled" :required="false">
          <el-switch
            v-model="form.ifEnabled"
            active-text="启用"
            inactive-text="停用"
            :active-value="0"
            :inactive-value="1">
          </el-switch>
        </RequiredFormItem>
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import {
  addDealer,
  editDealer,
  getBossDataList,
  getBossDetail
} from '@/api/businessOos'
import { getCityInfoData } from '@/api/flowSetting/cityInfoManage'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
//city+area+town
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
export default {
  props: {
    selection: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      required: true,
      loading: false,
      options: [
        {
          label: '停用',
          value: 1
        },
        {
          label: '启用',
          value: 0
        }
      ],
      form: {
        id: '',
        bossId: '', //实际控股人id
        channelType: '', //经销商分类
        secondaryChannelType: '', //经销商二级分类
        thirdChannelType: '', //经销商三级分类
        authorizedCityCode: '', //授权城市编号
        authorizedCityName: '', //授权城市名称
        marketLevelCode: '', //市场级别编号
        marketLevelName: '', //市场级别名称
        source: '', //来源
        sourceClassification: '', //来源分类
        ifEnabled: 0,
        createTime: '',
        updateTime: '',
        dealerLevel: '', // AB 商
        firstOpeningTime: '', // 首批摆场样品下单终审时间
      },
      total: null,
      bossSelectedItem: {
        bossNumber: '',
        idCard: '',
        dealerCode: '',
        dealerName: ''
      },
      authorizedCityTotal: null,
      authorizedCity: null
    }
  },
  components: {
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect,
    RequiredFormItem
  },

  mounted() {
    console.log(this.$refs)
    if (this.selection) {
      for (const key in this.form) {
        this.form[key] = this.selection[key]
      }
      this.bossSelectedItem = this.selection
      this.required = false
    } else {
      this.required = true
    }
  },
  methods: {
    async onNotFoundCity({ value: authorizedCityCode }, callback) {
      callback(
        await getCityInfoData({
          authorizedCityCode
        }).then(res => res.data)
      )
    },
    getCityInfoData({ page, searchText }) {
      return getCityInfoData({
        currPage: page,
        addr: searchText
      }).then(res => {
        this.authorizedCityTotal = res.totalCount
        return res.data
      })
    },
    async onNotFoundBoss({ value: bossId }, callback) {
      let res = await getBossDetail({ id: bossId })
      callback(
        await getBossDataList({
          bossNumber: res.data[0].bossNumber
        }).then(response => {
          return response.data
        })
      )
    },
    getBossDataList({ page, searchText }) {
      return getBossDataList({
        currPage: page,
        searchKeyword: searchText
      }).then(res => {
        this.total = res.totalCount
        return res.data
      })
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.selection && this.selection.id) {
            this.loading = true
            editDealer(this.form)
              .then(res => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.$emit('update:visible', false)
                  this.$refs['form'].resetFields()
                  this.$emit('clearSelection')
                  this.$emit('getDataList')
                } else {
                  this.$message.error(res.msg)
                }
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            this.loading = true
            addDealer(this.form)
              .then(res => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.$emit('update:visible', false)
                  this.$refs['form'].resetFields()
                  this.$emit('clearSelection')
                  this.$emit('getDataList')
                } else {
                  this.$message.error(res.msg)
                }
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    cancel() {
      this.$emit('clearSelection')
      this.$emit('update:visible', false)
    },
    init() {
      this.$refs['form'].resetFields()
      this.required = true
      this.loading = false
      this.total = null
      this.bossSelectedItem = {
        bossNumber: '',
        idCard: '',
        dealerCode: '',
        dealerName: ''
      }
      this.authorizedCityTotal = null
      this.authorizedCity = null
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/flowForm.scss';
.demo-drawer__footer {
  margin-bottom: 20px;
}
</style>
