var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("新增")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.del}},[_vm._v("批量失效")])],1),_c('el-table',{ref:"filterTable",staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.dataList,"header-cell-style":{ background: '#F4F5F8', color: '#393F4D' }},on:{"row-click":_vm.rowClick,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"name","label":"授权系列"}}),_c('el-table-column',{attrs:{"prop":"brandArea","label":"品牌门店面积"}}),_c('el-table-column',{attrs:{"prop":"sampleSets","label":"品牌样品套数"}}),_c('el-table-column',{attrs:{"prop":"authorizedTime","label":"门店系列授权时间"}}),_c('el-table-column',{attrs:{"prop":"overTime","label":"门店系列取消时间"}}),_c('el-table-column',{attrs:{"prop":"sort","label":"操作","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(scope.row.ifEnabled === 1)?_c('el-button',{on:{"click":function($event){return _vm.onWorking(scope.row)}}},[_vm._v("生效")]):_vm._e(),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.edit(scope.row.id)}}},[_vm._v(" 修改 ")])],1)]}}])})],1),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"450px","append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px"}},[_c('required-form-item-select',{attrs:{"dataList":_vm.authorizedBrand,"label":"门店系列","prop":"code","labelKey":"name","valueKey":"code","selectProps":{
          disabled: false,
          clearable: true,
          filterable: true
        }},on:{"changeSelectedItem":value => {
            _vm.getBrandData(value)
          }},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('required-form-item-select',{attrs:{"label":"售达方","prop":"businessLicenseId","dataList":_vm.authorzedList,"labelKey":"businessLicenseName","valueKey":"id","selectProps":{
          disabled: false,
          clearable: true
        }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.licenseNumber))]),_vm._v(" -- "),_c('small',[_vm._v(_vm._s(scope.row.businessLicenseName))])]}}]),model:{value:(_vm.form.businessLicenseId),callback:function ($$v) {_vm.$set(_vm.form, "businessLicenseId", $$v)},expression:"form.businessLicenseId"}}),_c('el-form-item',{attrs:{"label":"品牌面积"}},[_c('el-input',{attrs:{"type":"number","placeholder":"数字，<9999"},model:{value:(_vm.form.brandArea),callback:function ($$v) {_vm.$set(_vm.form, "brandArea", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.brandArea"}})],1),_c('el-form-item',{attrs:{"label":"样品套数"}},[_c('el-input',{attrs:{"type":"number","placeholder":"数字，<9999"},model:{value:(_vm.form.sampleSets),callback:function ($$v) {_vm.$set(_vm.form, "sampleSets", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sampleSets"}})],1),_c('el-form-item',{attrs:{"label":"门店系列授权时间"}},[_c('el-date-picker',{attrs:{"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd HH:mm:ss","type":"date","placeholder":"请选择门店系列授权时间"},model:{value:(_vm.form.authorizedTime),callback:function ($$v) {_vm.$set(_vm.form, "authorizedTime", $$v)},expression:"form.authorizedTime"}})],1),_c('el-form-item',{attrs:{"label":"门店系列取消时间"}},[_c('el-date-picker',{attrs:{"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd HH:mm:ss","type":"date","placeholder":"请选择门店系列取消时间"},model:{value:(_vm.form.overTime),callback:function ($$v) {_vm.$set(_vm.form, "overTime", $$v)},expression:"form.overTime"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addBrand}},[_vm._v("保 存")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }