<script>
import { getBrandseriesList } from '@/api/businessOos'
export default {
  props: {
    title: {
      type: String,
      default: '导出excel表格'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brandList: []
    }
  },
  created() {
    this.getBrandList()
  },
  methods: {
    async getBrandList() {
      try {
        const res = await getBrandseriesList({ brandLevel: 0, ifDelete: 0 })
        if (res.code === 0) {
          this.brandList = res.data
        }
      } catch (err) {
        console.log(err)
        this.$message({
          type: 'error',
          message: '获取品牌列表失败'
        })
      }
    },
    downloadExcel(item) {
      this.$emit('downloadExcel', item.id)
    }
  }
}
</script>
<template>
  <el-dropdown class="dropdown">
    <el-button type="primary" style="margin-left: 10px" :loading="loading">
      {{ title }}<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <div class="dropdown-menu-box">
        <el-dropdown-item v-for="item in brandList" :key="item.id">
          <div @click="downloadExcel(item)">{{ item.name }}</div>
        </el-dropdown-item>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<style lang="scss">
.dropdown-menu-box {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
