<template>
  <div class="container">
    <el-form
      status-icon
      class="flow-form"
      ref="ruleForm"
      :inline="true"
      :model="formData"
      label-position="top"
    >
      <HeaderTitle style="margin-top: 20px" title="营业执照信息" />

      <component
        delay-update
        :required="false"
        :placeholder="placeholder[key] || null"
        class="formItem"
        v-for="(value, key) in formLayout.dealerBusinessLicense"
        v-model="formData[key]"
        :prop="key"
        :is="isElement(key)"
        :key="key"
        :label="value"
        :type="key === 'businessLicenseType' ? 'business_license_type' : ''"
        :validations="validations[key] || []"
      />
      <required-module-form-item-select
      :required="false"
        class="formItem"
        prop="ifEnabled"
        v-model="formData.ifEnabled"
        label="是否生效"
        type="ifEnabled"
      />

      <HeaderTitle style="margin-top: 20px" title="开票信息" />

      <required-form-item-input
      :required="false"
        delay-update
        class="formItem"
        v-for="(value, key) in formLayout.dealerBusinessLicense2"
        v-model="formData[key]"
        :prop="key"
        :label="value"
        :key="value"
      />
      <HeaderTitle style="margin-top: 20px" title="纳税信息" />

      <required-module-form-item-select
      :required="false"
        class="formItem"
        prop="payTaxesType"
        v-model="formData.payTaxesType"
        label="纳税类型"
        type="pay_taxes_type"
      />
      <required-module-form-item-select
      :required="false"
        class="formItem"
        prop="billType"
        v-model="formData.billType"
        label="专票/普票"
        module="public"
        type="invoice_type"
      />
      <required-form-item-input
        :required="false"
        delay-update
        :placeholder="placeholder[key] || null"
        class="formItem"
        :prop="key"
        v-for="(value, key) in formLayout.dealerBusinessLicense3"
        :key="key"
        v-model="formData[key]"
        :label="value"
        :validations="validations[key] || []"
      />
      <HeaderTitle style="margin-top: 20px" title="海外售达方信息" />
      <component
        v-for="(value, key) in formLayout.dealerBusinessLicense4"
        :is="
          value.includes('是否') || !!selectTypeArray[value]
            ? 'requiredModuleFormItemSelect'
            : 'requiredFormItemInput'
        "
        readonly
        :required="false"
        delay-update
        class="formItem"
        v-model="formData[key]"
        :prop="key"
        :label="value"
        :key="value"
        :type="
          value.includes('是否')
            ? 'flow_common_select_ifEnable'
            : selectTypeArray[value]
        "
      />
    </el-form>
  </div>
</template>

<script>
import { businesslicenseUpdate } from '@/api/businessOos'
import { datadictionaryList } from '@/api/common'
import data from '@/components/CustomFormItem/data/module.data'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import requiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import requiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker'
import requiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import HeaderTitle from '@/views/flow/components/headerTitle'
const selectTypeArray = {
  '交易条款编码':'exchange_provision',
  '销售渠道编码':'cooperation_code',
  '销售部门编码':'overseas_department',
  '客户等级编码':'client_grade',
  '产品档次编码':'product_level',
  '客户来源':'dealer_source',
  '客户状态':'distributor_state_code',
  '终止合作原因编码':'cooperation_end_reason'
}
const validations = {
  realName: ['name'],
  contactInfo: ['phone'],
  idCard: ['user_id'],
  tencentQq: ['email'],
  creditCode: ['credit_code'],
  // taxpayerPhone: ['phone'],
  bankAccount: ['bank_account'],
  personLiableName: ['name'],
  personLiablePhone: ['phone'],
  email: ['email'],
  renovationLiablePerson: ['name'],
  renovationPersonPhone: ['phone'],
  contactPerson: ['name'],
  contactPersonIdcard: ['user_id'],
  contactPersonPhone: ['phone'],
  warehouseUser: ['name'],
  warehousePhone: ['phone'],
  legalPersonContact: ['phone'],
  age: ['age'],
  // accountName: ['name'],
  account: ['bank_account']
}

export default {
  components: {
    RequestModuleSelect,
    requiredFormItemInput,
    requiredFormItemDatePicker,
    requiredModuleFormItemSelect,
    HeaderTitle
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    getDataList: {
      type: Function,
      default: () => {}
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectTypeArray,
      formData: {},
      shopNatureOption: [],
      storeTypeOPtion: [],
      partnerShipOption: [],
      marketOption: [],
      validations,
      formLayout: {
        dealerBusinessLicense: {
          businessLicenseType: '营业执照类型',
          registeredCapital: '注册资本（万元)',
          legalPersonName: '法人',
          legalPersonContact: '法人联系方式',
          registerDate: '注册日期',
          businessTerm: '营业期限',
          businessLicenseAddress: '营业执照地址（住所）',
          businessScope: '经营范围',
          registrationAuthority: '登记机关',
          certificatesRecAddress: '函证接收地址',
          eMail: '接受电子发票邮箱'
        },
        dealerBusinessLicense2: {
          bank: '开户行',
          bankAccount: '银行账号',
          bankAccountName: '开户账号名称',
          businessLicenseName: '开票名称',
          // creditCode: '纳税人识别号',
          taxpayerPhone: '纳税人电话'
        },
        dealerBusinessLicense3: {
          personLiableName: '税务负责人',
          personLiablePhone: '税务负责人电话'
        },
        dealerBusinessLicense4: {
          countryCode: '国家',
          firstAdministrativeRegionCode: '一级行政区域编码', // 一级行政区域编码
          secondAdministrativeRegionCode: '二级行政区域编码', // 二级行政区域编码
          contactPersonName: '联系人姓名', // 联系人姓名
          contactPersonPhone: '联系人电话', // 联系人电话
          tradingItem: '交易条款', // 交易条款
          customerLevelCode: '客户等级编码', // 客户等级编码
          productGradeCode: '产品档次编码', // 产品档次编码
          distributionChannelCode: '销售渠道编码', // 销售渠道编码
          salesDepartmentCode: '销售部门编码', // 销售部门编码
          invoicePackingListOriginalFlag: '发票箱单是否正本', // 发票箱单是否正本
          billOfLadingOriginalFlag: '提单是否正本', // 提单是否正本
          stiflingFlag: '是否需要产地证', // 是否需要产地证
          certificateOfOriginFlag: '是否需要熏蒸', // 是否需要熏蒸
          certificateOfOriginOriginalFlag: '产地证是否正本', // 产地证是否正本
          receiveCompanyName: '收货公司名称', // 收货公司名称
          receiveCompanyAddress: '收货方地址', // 收货方地址
          consigneeName: '收货方姓名', // 收货方姓名
          consigneeContact: '收货方联系方式', // 收货方联系方式
          consigneeEmail: '收货方邮箱', // 收货方邮箱
          notifyPartyCompanyName: '通知方公司名称', // 通知方公司名称
          notifyPartyAddress: '通知方地址', // 通知方地址
          notifyPartyName: '通知方名称', // 通知方名称
          notifyPartyContact: '通知方联系方式', // 通知方联系方式
          notifyPartyEmail: '通知方邮箱', // 通知方邮箱
          authorizeTime: '授权时间', // 授权时间
          bankCode: '银行编码', // 银行编码
          bankAccountAddr: '银行地址', // 银行地址
          customerOriginCode: '客户来源', // 客户来源
          customerStatusCode: '客户状态', // 客户状态
          stopCooperationReasonCode: '终止合作原因编码', // 终止合作原因编码
          stopCooperationTime: '终止合作时间', // 终止合作时间
          postCode: '邮政编码', // 邮政编码
          destinationPort: '出货目的港', // 出货目的港
          shippingCounterpart: '船务对接人', // 船务对接人
          shippingCounterpartEmail: '船务对接人邮箱' // 船务对接人邮箱
        }
      },
      placeholder: {
        registrationAuthority: '请填写营业执照右下方登记机关红章名称',
        bank: '详细至营业点，**分行/分理处',
        certificatesRecAddress: '实控人接收慕思财务下发函证资料地址',
        nativePlace: '广东东莞',
        capitalSource: '自有资金/政策贷款/其他',
        oaAccount: '归档后生成',
        advertisingInvestment: '年度广告投入具体单位新增（万）单位',
        businessTotalTime: '已从事时间指年份'
      }
    }
  },
  watch: {
    // dataList(val) {
    //   console.log(val);
    //   this.formData = val[0]
    // }
    dataList: {
      deep: true,
      immediate: true,
      handler(value) {
        
        this.formData = value[0]
        this.getCountries(value[0].countryCode)
      }
    }
  },
  methods: {
    getCountries(code) {
      datadictionaryList({type:'country',module:'public',code}).then(res => {
        if(res&&res.data&&res.data.length){
          this.formData.countryCode = res.data[0].content
        }
      })
    },
    isElement(key) {
      switch (key) {
        case 'registerDate':
          return 'required-form-item-date-picker'
        case 'businessLicenseType':
          return 'required-module-form-item-select'
        default:
          return 'required-form-item-input'
      }
    },
    onCommit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await businesslicenseUpdate(this.formData)
          this.$message.success('修改成功')
          this.cancel()
          this.getDataList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.formItem {
  width: 20%;
}

.container {
  padding: 0 20px;
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border: 1px solid #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}

.footer {
  margin: 10px 0;
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
