<template>
  <el-table ref="filterTable" border :data="dataList" :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}"
    style="width:100%">
    <el-table-column prop="brandName" label="系列" />
    <el-table-column prop="code" label="销售组织代码" />
    <el-table-column prop="name" label="销售组织" />
  </el-table>
</template>

<script>
  export default {
    props: {
      dataList: {
        type: Array,
        default: () => []
      }
    }
  }

</script>

<style lang='scss' scoped>

</style>
