<template>
  <div>
    <el-table
      ref="filterTable"
      border
      :data="dataList"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      style="width:100%"
    >
      <el-table-column prop="name" label="系列" />
      <el-table-column prop="orderSpecialist" label="订单专员" />
      <el-table-column prop="orderSpecialist" label="生效/失效" align="center">
        <template slot-scope="scope">
          <div>
            <el-switch
              :value="scope.row.ifEnabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="0"
              :inactive-value="1"
              @change="changeStatus(scope.row.id, scope.row.ifEnabled)"
            >
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" align="right">
        <template slot="header" slot-scope="scope">
          <el-button
            type="primary"
            @click="show(0)"
            size="mini"
            v-permission="['masterList_add']"
            >新增</el-button
          >
        </template>
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="show(1, scope.row)"
            size="mini"
            v-permission="['masterList_edit']"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="`${title}订单专员`"
      :visible.sync="dialogFormVisible"
      append-to-body
    >
      <el-form :model="form" ref="form" label-position="top">
        <required-form-item-select
          v-if="!status"
          ref="nameRef"
          class="formItem"
          prop="nameId"
          v-model="form.nameId"
          label="系列"
          labelKey="name"
          valueKey="id"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :selectListeners="{}"
          :total-count="nameTotal"
          :getDataList="getNameList"
          @changeSelectedItem="e => (nameSelectedItem = e)"
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.name }}（{{ scope.row.code }}）</span>
          </template>
        </required-form-item-select>
        <required-form-item-select
          ref="orderSpecialistRef"
          class="formItem"
          prop="bossNumber"
          v-model="form.bossNumber"
          label="订单专员"
          labelKey="realName"
          valueKey="bossNumber"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :selectListeners="{}"
          :total-count="total"
          :getDataList="getOrderSpecialist"
          :getNextList="getOrderSpecialist"
          @onOptionsListNotFound="onNotFoundOrderSpecialist"
          @changeSelectedItem="e => (orderSpecialistSelectedItem = e)"
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.realName }}（{{ scope.row.bossNumber }}）</span>
          </template>
        </required-form-item-select>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="updateOrSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBrandSeries,
  getBossList,
  addOrderspecialist,
  updateOrderspecialist,
  changeOrderspeciaStatus
} from '@/api/businessOos'
//,businessLicenseId,authorizedCityCode,brandId
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    rowData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      title: '',
      nameTotal: null,
      total: null,
      dialogFormVisible: false,
      orderSpecialistSelectedItem: null,
      nameSelectedItem: null,
      form: {
        nameId: '',
        bossNumber: ''
      },
      id: null,
      status: 0,
      row: null
    }
  },
  components: {
    RequiredFormItemSelect
  },
  methods: {
    updateOrSave() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('验证', valid)
          if (!this.status) {
            let data = {
              businessLicenseId: this.rowData.businessLicenseId,
              authorizedCityCode: this.rowData.authorizedCityCode,
              bigBrandId: this.rowData.brandId,
              brandId: this.nameSelectedItem.id,
              brandCode: this.nameSelectedItem.code,
              orderSpecialist: this.orderSpecialistSelectedItem.realName,
              orderSpecialistNumber: this.orderSpecialistSelectedItem.bossNumber
            }
            addOrderspecialist(data).then(res => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.dialogFormVisible = false
                this.$emit('update', 'order')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            let data = {
              id: this.id,
              orderSpecialist: this.orderSpecialistSelectedItem.realName,
              orderSpecialistNumber: this.orderSpecialistSelectedItem
                .bossNumber,
              ifEnabled: 0
            }
            updateOrderspecialist(data).then(res => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.dialogFormVisible = false
                this.$emit('update', 'order')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    cancel() {
      this.dialogFormVisible = false
    },
    show(status, row) {
      this.status = status
      if (status) {
        this.title = '修改'
        this.id = row.id
        // this.form.nameId = row.id
        // this.form.bossNumber = row.orderSpecialistNumber
      } else {
        this.title = '新增'
        this.id = null
      }
      this.form = {
        nameId: '',
        bossNumber: ''
      }

      this.dialogFormVisible = true
    },
    getOrderSpecialist({ page, searchText }) {
      let params = {
        currPage: page,
        roleCode: 16
      }
      if (/^\d+$/g.test(searchText)) {
        params = Object.assign({}, params, { bossNumber: searchText })
      } else {
        params = Object.assign({}, params, { realName: searchText })
      }
      return getBossList(params).then(res => {
        this.total = res.totalCount
        return res.data
      })
    },
    async onNotFoundOrderSpecialist({ value: bossNumber }, callback) {
      callback(
        await getBossList({
          bossNumber,
          roleCode: 16
        }).then(res => res.data)
      )
    },
    // 切换订单专员注释
    changeStatus(id, ifEnabled) {
      const data = {
        id,
        ifEnabled: ifEnabled === 0 ? 1 : 0
      }
      changeOrderspeciaStatus(data).then(() => {
        // console.log(res);
        this.$emit('update', 'order')
      })
    },
    getNameList({ page, searchText }) {
      let { brandId } = this.rowData
      return getBrandSeries({
        currPage: page,
        pageSize: 100,
        parentId: brandId,
        code: searchText
      }).then(res => {
        this.nameTotal = res.totalCount
        return res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
