<template>
  <el-table
    ref="filterTable"
    border
    :data="dataList"
    :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
    style="width: 100%">
    <el-table-column prop="dealer_code" label="经销商编号" />
    <el-table-column prop="dealer_name" label="经销商名称" />
    <el-table-column prop="cd_if_enabled" label="经销商状态">
      <template slot-scope="scope">
        <div>
          {{ scope.row.cd_if_enabled === 0 ? '启用' : '停用' }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="boss_name" label="实控人" />
    <el-table-column prop="authorized_city_name" label="授权城市" />
  </el-table>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped></style>
