<template>
  <div>
    <div style="margin:20px">
      <el-button type="primary" @click="add">新增</el-button>
      <el-button type="danger" @click="del">批量失效</el-button>
    </div>
    <el-table
      ref="filterTable"
      border
      :data="dataList"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      style="width:100%"
      @row-click="rowClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="brandName" label="授权系列" />
      <el-table-column prop="authorizedCityName" label="授权城市" />
      <el-table-column prop="ifEnabled" label="状态">
        <template slot-scope="scope">
          <div>
            {{ scope.row.ifEnabled === 1 ? '失效' : '启用中' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div @click.stop="">
            <el-button type="success" @click="edit(scope.row.id)">
              修改
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="375px" append-to-body>
      <el-form ref="form" :model="form" label-width="80px">
        <!-- <el-form-item label="授权系列"> -->
        <required-form-item-select
          :dataList="authorizedBrand"
          prop="brandId"
          label="授权系列"
          v-model="form.brandId"
          labelKey="name"
          valueKey="id"
          :selectProps="{
            disabled: false,
            clearable: true,
            filterable: true
          }"
          @changeSelectedItem="
            value => {
              if (value) {
                form.bigBrandId = value.parentId
                form.brandCode = value.code
              }
            }
          "
        />
        <!-- </el-form-item> -->
        <!-- <el-form-item label="授权城市"> -->
        <required-form-item-select
          :dataList="cityList"
          ref="authorizedCityRef"
          prop="crmDistributorCode"
          :getDataList="getAsyncCityList"
          :getNextList="getAsyncCityList"
          :totalCount="totalCount"
          label="经销商"
          v-model="form.crmDistributorCode"
          labelKey="dealerName"
          valueKey="dealerCode"
          :selectProps="{
            remote: true,
            'popper-class': 'custom-business-select',
            filterable: true,
          }"
          @changeSelectedItem="
            value => {
              getDealerList(value)
            }
          "
          @onOptionsListNotFound="onOptionsListNotFound"
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.dealerName }}</span>
            <small>({{ scope.row.dealerCode }})</small>
          </template>
        </required-form-item-select>
        <el-form-item label="合作类型">
            <el-select v-model="channelCooperationType" placeholder="请选择送达方渠道合作类型">
            <el-option label="加盟业务" :value="10"/>
            <el-option label="新渠道业务" :value="20"/>
          </el-select>
        </el-form-item>
        
        <!-- </el-form-item> -->
        <!-- <el-form-item label="经销商"> -->
        <!-- <required-form-item-select
          :dataList="businessList"
          v-model="form.crmDistributorCode"
          prop="crmDistributorCode"
          label="经销商"
          labelKey="distributorName"
          valueKey="dealerCode"
          :disabled="!form.authorizedCityCode"
          :selectProps="{
            clearable: true,
            filterable: true
          }"
          @changeSelectedItem="
            value => {
              form.crmDistributorId = value ? value.dealerId : ''
            }
          "
        /> -->
        <!-- </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addBrand">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FormInput from '@/components/CustomFormItem/requiredFormItemInput'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import { getBusinessBrandList, getBusinessCityList } from '@/api/businessOos'
import { brandseriesList } from '@/api/common'
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    getEditShopBrand: {
      type: Function,
      default: () => {}
    },
    bossId: {
      type: String,
      default: ''
    }
  },
  components: {
    FormInput,
    RequiredFormItemSelect
  },
  data() {
    return {
      rowId: [],
      dialogVisible: false,
      form: {},
      authorizedBrand: [], //系列列表数据
      authorizedCityTotal: null,
      cityList: [],
      totalCount:0,
      channelCooperationType:''
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getBusinessBrandData()
          this.getCityInfoData()
        }
      }
    }
  },
  methods: {
    // 获取授权城市
    async getCityInfoData() {
      const res = await getBusinessCityList({
        bossId: this.bossId,
        currPage:  1,
        pageSize: 10
      })
      this.cityList = res.data
      this.totalCount = res.totalCount
    },
    // 获取分页城市列表
    async getAsyncCityList({ searchText, page }) {
      const res = await getBusinessCityList({
        bossId: this.bossId,
        authorizedCityName: searchText,
        currPage: page || 1,
        pageSize: 10
      })
      if (res.code === 0) {
        // this.businessList = [...this.businessList, ...res.data]
        if (res.currPage == 1) {
          this.cityList = [...res.data]
        } else if (res.currPage > 1) {
          this.cityList = [...this.cityList, ...res.data]
        }
        return res.data
      }
    },
    async onOptionsListNotFound({ value, getDataListParams }, callback) {
      const res = await getBusinessCityList({
        bossId: this.bossId,
        authorizedCityName: value,
        currPage:  1,
        pageSize: 10
      })
      callback(res.data)
    },
    // 获取列表系列
    async getBusinessBrandData() {
      const res = await brandseriesList({ brandLevel: 1 })
      this.authorizedBrand = res.data
    },
    // 获取经销商信息
    async getDealerList(value) {
      if (value) {
        this.form.crmDistributorId = value.id
        this.form.authorizedCityCode = value.authorizedCityCode
      }
    },
    //
    edit(id) {
      this.dialogVisible = true
      const data = this.dataList.find(item => item.id == id)
      this.form = { ...data }
    },
    add() {
      for (let key in this.form) {
        this.form[key] = ''
      }
      this.dialogVisible = true
    },
    // 保存
    addBrand() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const object = {
            ifEnabled: 0,
            statusExplain: '30',
            channelCooperationType: this.channelCooperationType,
          }
          this.form.businessLicenseId = this.id
          this.form = { ...this.form, ...object }
          this.$emit('onSave', this.form)
          this.dialogVisible = false
        } else {
          this.$message({
            type: 'warning',
            message: '必填项不能为空'
          })
          return false
        }
      })
    },
    del() {
      if (this.rowId.length == 0) {
        this.$message.warning('请选择需要失效的授权系列')
        return
      }
      this.$confirm('确定失效?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('onDelete', this.rowId)
      })
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    }
  }
}
</script>

<style lang="scss" scoped></style>
