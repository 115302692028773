<template>
  <div>
    <!-- <el-tabs v-if="tabs" v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="传统授权城市" name="old"></el-tab-pane>
      <el-tab-pane label="新渠道授权城市" name="new"></el-tab-pane>
    </el-tabs> -->
    <el-table ref="filterTable" border :data="dataList" :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}"
      style="width:100%">
      <el-table-column prop="marketLevelName" label="城市等级" />
      <el-table-column prop="authorizedCityName" label="授权城市" />
      <el-table-column prop="province" label="省份" />
      <el-table-column prop="city" label="市">
      </el-table-column>
      <el-table-column prop="area" label="区/县" />
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      dataList: {
        type: Array,
        default: () => []
      },
      tabs: {
        type: Boolean,
        default: false
      },
      onChange: {
        type: Function,
        default: () => {}
      },
      data: {
        type: Object,
        default:()=>({})
      }
    },
    data() {
      return {
        activeName: 'old'
      }
    },
    methods: {
      handleClick() {
        // const id = this.$route.query.id
        this.onChange(this.data, this.activeName)
      }
    },
  }

</script>

<style lang='scss' scoped>

</style>
