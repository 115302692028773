<template>
  <div class="container">
    <el-form :inline="true" :model="formData" label-position="top">
      <div class="shopTypeSelect">
        <el-form-item class="formItem" label="实控人：">
          <el-input v-model="formData.realName"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="实控人身份证：">
          <el-input v-model="formData.idCard"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="联系方式：">
          <el-input v-model="formData.contactInfo"></el-input>
          <!-- <RequestModuleSelect :value="formData.partnership" type="partnership" disabled /> -->
        </el-form-item>
        <el-form-item class="formItem" label="QQ：">
          <el-input v-model="formData.tencentQq"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="电子邮箱：">
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="学历：">
          <RequestModuleSelect v-model="formData.education" type="education" />
        </el-form-item>
        <el-form-item class="formItem" label="年龄：">
          <el-input v-model="formData.age"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="籍贯：">
          <el-input v-model="formData.nativePlace"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="性别：">
          <RequestModuleSelect v-model="formData.sex" type="sex" />
        </el-form-item>
        <el-form-item class="formItem" label="婚姻：">
          <RequestModuleSelect
            v-model="formData.maritalStatus"
            type="marital_status"
          />
        </el-form-item>
      </div>
    </el-form>
    <div style="margin-bottom:30px">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button  @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import { updateBossData } from '@/api/businessOos'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
export default {
  components: {
    RequestModuleSelect
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    cancel: {
      type: Function,
      default: () => {}
    },
    getDataList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {},
      shopNatureOption: [],
      storeTypeOPtion: [],
      partnerShipOption: [],
      marketOption: []
    }
  },
  watch: {
    // dataList(val) {
    //   console.log(val);
    //   this.formData = val[0]
    // }
    dataList: {
      deep: true,
      // immediate:true,
      handler(value) {
        this.formData = value[0]
      }
    }
  },
  created() {
    // const typeList = ['shop_nature_code', 'partnership', 'store_type', 'market']
    // typeList.forEach(item => {
    //   this.getShopType(item)
    // })
  },
  methods: {
    async save() {
      const res = await updateBossData(this.formData)
      if (res.code === 0) {
        this.$message({
          type:'success',
          message:'修改成功'
        })
        this.cancel()
        this.getDataList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.formItem {
  width: 20%;
}

.container {
  padding: 0 20px;
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border: 1px solid #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
