var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{ref:"filterTable",staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.dataList,"header-cell-style":{ background: '#F4F5F8', color: '#393F4D' }}},[_c('el-table-column',{attrs:{"prop":"name","label":"系列"}}),_c('el-table-column',{attrs:{"prop":"orderSpecialist","label":"订单专员"}}),_c('el-table-column',{attrs:{"prop":"orderSpecialist","label":"生效/失效","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-switch',{attrs:{"value":scope.row.ifEnabled,"active-color":"#13ce66","inactive-color":"#ff4949","active-value":0,"inactive-value":1},on:{"change":function($event){return _vm.changeStatus(scope.row.id, scope.row.ifEnabled)}}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['masterList_add']),expression:"['masterList_add']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.show(0)}}},[_vm._v("新增")])]}},{key:"default",fn:function(scope){return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['masterList_edit']),expression:"['masterList_edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.show(1, scope.row)}}},[_vm._v("修改")])]}}])})],1),_c('el-dialog',{attrs:{"title":`${_vm.title}订单专员`,"visible":_vm.dialogFormVisible,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-position":"top"}},[(!_vm.status)?_c('required-form-item-select',{ref:"nameRef",staticClass:"formItem",attrs:{"prop":"nameId","label":"系列","labelKey":"name","valueKey":"id","selectProps":{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        },"selectListeners":{},"total-count":_vm.nameTotal,"getDataList":_vm.getNameList},on:{"changeSelectedItem":e => (_vm.nameSelectedItem = e)},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.name)+"（"+_vm._s(scope.row.code)+"）")])]}}],null,false,2967606541),model:{value:(_vm.form.nameId),callback:function ($$v) {_vm.$set(_vm.form, "nameId", $$v)},expression:"form.nameId"}}):_vm._e(),_c('required-form-item-select',{ref:"orderSpecialistRef",staticClass:"formItem",attrs:{"prop":"bossNumber","label":"订单专员","labelKey":"realName","valueKey":"bossNumber","selectProps":{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        },"selectListeners":{},"total-count":_vm.total,"getDataList":_vm.getOrderSpecialist,"getNextList":_vm.getOrderSpecialist},on:{"onOptionsListNotFound":_vm.onNotFoundOrderSpecialist,"changeSelectedItem":e => (_vm.orderSpecialistSelectedItem = e)},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.realName)+"（"+_vm._s(scope.row.bossNumber)+"）")])]}}]),model:{value:(_vm.form.bossNumber),callback:function ($$v) {_vm.$set(_vm.form, "bossNumber", $$v)},expression:"form.bossNumber"}})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.updateOrSave}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }