import { render, staticRenderFns } from "./editShopBrandTable.vue?vue&type=template&id=146722fa&scoped=true"
import script from "./editShopBrandTable.vue?vue&type=script&lang=js"
export * from "./editShopBrandTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146722fa",
  null
  
)

export default component.exports