<template>
  <div class="container">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="单号">
        <el-input v-model="formInline.number"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button type="warning" @click="handleChangeOa">切换{{!isChange?'装修补贴流程':''}}</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="filterTable"
      border
      :data="dataList"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      style="width: 100%"
    >
      <el-table-column prop="title" label="流程名称" />
      <el-table-column prop="orderNumber" label="流程编号" />
      <el-table-column prop="optUserName" label="提单人" />
      <el-table-column prop="optTime" label="提单时间" />
      <el-table-column prop="statusExplain" label="流程状态" />
    </el-table>
    <!-- <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      />
    </div> -->
  </div>
</template>

<script>
import { dataRelatedFlow, requestOaGetDataList } from '@/api/businessOos'
export default {
  name: 'relevantTable',
  props: {
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    rows: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      formInline: {
        number: ''
      },
      dataList: [],
      dataListForOa: [],
      dataListForLocal: [],
      total: 0,
      limit: 10,
      page: 1,
      selectRow: '',
      isChange: false
    }
  },
  watch: {
    id: {
      handler(value) {
        // console.log(value)
        value &&
          this.getDataList({
            id: value,
            type: this.type
          })
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(()=> {
      this.getOADataList()
    })
  },
  methods: {
    async getDataList({ id, type, key = '' }) {
      const res = await dataRelatedFlow({
        dataId: id,
        tableName: type,
        orderNumber: key
      })
      this.dataList = res.data
      this.dataListForLocal = res.data
    },
    async getOADataList() {
      const {data} = await requestOaGetDataList({
        pathUrl: `/investment/getWorkFlowByStoreNo/${this.rows.shopNumber}`,
        method: 'get'
      })
      this.dataListForOa = data?.data
    },
    // 页面切换
    currentChange(page) {
      this.page = page
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
    },
    query() {
      this.getDataList({
        id: this.id,
        type: this.type,
        key: this.formInline.number
      })
    },
    handleChangeOa() {
      this.isChange = !this.isChange
      if (this.isChange) {
        this.dataList = this.dataListForOa
      }else {
        this.dataList = this.dataListForLocal
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/mixin';
.container {
  padding: 0 20px;
}
.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}
</style>
