<template>
  <div class="view">
    <el-row type="flex" :gutter="20">
      <el-col :span="14">
        <el-input
          placeholder="请输入内容"
          v-model="account"
          class="input-with-select"
        >
          <el-select v-model="select" slot="prepend" placeholder="请选择">
            <el-option label="用户名" value="realName"></el-option>
            <el-option label="登录名" value="code"></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onSearch"
          ></el-button>
        </el-input>
      </el-col>
      <el-col>
        <el-button
          :loading="isLoading"
          type="primary"
          @click="openPowerPanel()"
        >
          数据授权
        </el-button>
      </el-col>
    </el-row>
    <div style="height: 40px"></div>

    <el-table
      fit
      border
      stripe
      ref="table"
      :data="list"
      style="width: 100%"
      @row-click="onRowClcik"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column width="60" type="index" label="序号"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="code" label="登录名">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="realName" label="用户名">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="phone" label="手机号">
      </el-table-column>
      <el-table-column prop="status" label="状态"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="createTime" label="创建时间">
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>

    <el-drawer
      title="数据权限"
      :visible.sync="drawer"
      direction="rtl"
      size="60%"
    >
      <div class="casecader-list" v-loading="isLoading">
        <div class="casecader-item">
          <el-checkbox
            class="checked-all"
            v-model="checkedBrand"
            @change="onAllCheckedBrand"
          >
            品牌全选
          </el-checkbox>
          <el-checkbox-group v-model="checkboxGroupBrand">
            <el-cascader-panel
              ref="casecaderBrand"
              class="cascader-brand my-cascader"
              :options="options"
              :props="{ multiple: true }"
            >
              <template v-slot="{ data }">
                <div class="casecader-brand-checkbox">
                  <el-checkbox
                    class="my-checked"
                    ref="checkboxRef"
                    :name="data.value"
                    :label="data.value"
                    @change="value => onSelectBrandCheckbox(value, data)"
                  >
                  </el-checkbox>
                  <p
                    class="casecader-label"
                    :class="{ active: currentBrandId == data.value }"
                    @click="onBrandActive(data)"
                  >
                    <label>{{ data.label }}</label>
                    <i
                      v-if="currentBrandId == data.value"
                      class="el-icon-caret-right"
                    ></i>
                  </p>
                </div>
              </template>
            </el-cascader-panel>
          </el-checkbox-group>
        </div>
        <div class="casecader-list">
          <div class="casecader-item">
            <el-checkbox
              class="checked-all"
              v-model="checkedArea"
              @change="onAllCheckedArea"
            >
              区域全选
            </el-checkbox>
            <el-cascader-panel
              ref="casecaderArea"
              v-model="checkboxGroupArea"
              class="my-cascader"
              placeholder="试试搜索：东莞"
              :options="provinceList"
              :props="{ multiple: true }"
              @change="onCaseaderAreaChange"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader-panel>
          </div>
        </div>
      </div>
      <p class="casecader-tips">
        <i class="el-icon-caret-right"></i>
        <label>表示当前选中的品牌</label>
        <el-checkbox class="checked-icon" :value="false">
          表示未选择
        </el-checkbox>
        <el-checkbox class="checked-icon" :value="true">表示选中</el-checkbox>
      </p>
      <div style="margin: 40px 20px">
        <el-button :loading="isLoading" @click="drawer = false">取消</el-button>
        <el-button :loading="isLoading" type="primary" @click="complete"
          >确定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import * as Http from '@/api/memberManager'
import * as CommonApi from '@/api/common'

export default {
  name: 'MemberManager',
  components: {},
  props: {},
  data() {
    return {
      drawer: false,
      account: '',
      select: 'realName',
      currPage: 1,
      pageSize: 10,
      total: 1000,
      list: [],
      multipleSelection: [],
      provinceList: [], //记录有多少区域数据
      options: [], //记录有多少品牌数据
      checkboxGroupArea: [], //记录区域数组
      checkboxGroupBrand: [], //记录品牌数组
      checkedBrand: false, //品牌全选
      checkedArea: false, //区域全选
      isLoading: false,
      brandAreaList: [], //记录要提交的数据
      currentBrandId: '', //记录当前编辑的品牌
      editSourceArr: [] //记录当前用户已存在的权限数据
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.list = await this.getList()
      this.getBrandList()
      this.getProvinceOrCityOrAreaList()
    },
    async getBrandList() {
      let { code, data } = await CommonApi.brandseriesList({ brandLevel: 1 })
      if (code != 0) return
      this.options = data.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
      this.currentBrandId = this.options[0].value
    },
    async getList(params) {
      let { code, data, totalCount, totalPage } = await Http.getList({
        currPage: 1,
        pageSize: 10,
        loginType: 10,
        sourceNum: 20,
        ...params
      })
      if (code != 0) return
      this.total = totalCount
      this.pageSize = totalPage
      return data.map(item => ({
        ...item,
        status: item.status == 0 ? '正常' : '失效'
      }))
    },
    async getProvinceOrCityOrAreaList(params = {}) {
      let { code, data } = await CommonApi.getProvinceWidthCity({
        countryCode: 'CN'
      })
      if (code != 0) return
      this.provinceList = [
        ...data.map(item => {
          return {
            label: item.provinceName,
            value: item.provinceCode,
            children: item.childenList.map(option => ({
              label: option.cityName,
              value: option.cityCode
            }))
          }
        }),
        { label: '海外', value: '1', children: [{ label: '城市', value: '1' }] }
      ]
    },
    async getPower({ userId, userCode }) {
      let { code, data } = await Http.getPower({ userId, userCode })
      if (code != 0) return []
      return data
    },
    async onCurrentChange(value) {
      this.list = await this.getList({ currPage: value })
    },
    async onSearch() {
      this.list = await this.getList({
        [this.select]: this.account
      })
    },
    onRowClcik(row, column, event) {
      this.$refs.table && this.$refs.table.toggleRowSelection(row)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    //打开授权面板
    async openPowerPanel(type = 0) {
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择需要更改权限的用户')
        return
      }
      this.brandAreaList = []
      this.editSourceArr = []
      this.drawer = true
      this.isLoading = true
      if (this.multipleSelection.length == 1) {
        let data = await this.getPower({
          userId: this.multipleSelection[0].id,
          userCode: this.multipleSelection[0].code
        })

        this.editSourceArr = data

        this.brandAreaList = data.map(item => ({
          seriesId: item.seriesId,
          provinceCode: item.provinceCode,
          cityCode: item.cityCode == 0 ? '' : item.cityCode
        }))

        this.checkboxGroupBrand = data.map(item => item.seriesId)
        this.checkboxGroupArea = this.showBrandActiveWidthArea()
      } else {
        this.checkboxGroupBrand = []
        this.checkboxGroupArea = []
      }
      this.isLoading = false
    },
    //展示当前品牌对应区域
    showBrandActiveWidthArea(id, data) {
      try {
        if (this.brandAreaList.length == 0) return []
        const arr = this.brandAreaList.filter(
          item => item.seriesId == this.currentBrandId
        )
        return arr
          .map(item => {
            if (item.cityCode == 0) {
              const findItem = this.provinceList.find(
                province => province.value == item.provinceCode
              )
              if (!findItem) return [[item.provinceCode, '']]
              return findItem.children.map((child = {}) => [
                item?.provinceCode,
                child?.value
              ])
            }
            return [[item.provinceCode, item.cityCode]]
          })
          .flat()
      } catch (e) {
        console.log(e)
      }
    },
    //全选、取消品牌
    onAllCheckedBrand(value) {
      this.onAllCheckedArea(value)
      this.checkedArea = value
      if (value) {
        this.checkboxGroupBrand = this.options.map(item => item.value)
        this.$nextTick(() => {
          this.brandAreaList = this.options
            .map(option => {
              return this.provinceList.map(province => {
                return {
                  seriesId: option.value,
                  provinceCode: province.value,
                  cityCode: ''
                }
              })
            })
            .flat()
        })
      } else {
        this.checkboxGroupBrand = []
        this.brandAreaList = []
      }
    },
    onBrandActive(item) {
      this.currentBrandId = item.id
      this.checkboxGroupArea = this.showBrandActiveWidthArea()
    },
    //品牌选项框触发
    onSelectBrandCheckbox(value, item) {
      this.currentBrandId = item.id
      if (value) {
        this.checkboxGroupBrand.push(item.id)
      } else {
        this.checkboxGroupBrand = this.checkboxGroupBrand.filter(
          id => id != item.id
        )
      }
      this.onAllCheckedArea(value)
      this.checkedArea = value
    },
    //全选、取消区域
    onAllCheckedArea(value) {
      if (value) {
        this.checkboxGroupArea = this.provinceList
          .map(item => {
            return item.children.map(child => [item.value, child.value])
          })
          .flat()
        this.checkboxGroupBrand.push(this.currentBrandId)
        this.brandAreaList = [
          ...this.brandAreaList.filter(
            item => item.seriesId != this.currentBrandId
          ),
          this.provinceList.map(province => {
            return {
              seriesId: this.currentBrandId,
              provinceCode: province.value,
              cityCode: ''
            }
          })
        ].flat()
      } else {
        this.$refs.casecaderArea && this.$refs.casecaderArea.clearCheckedNodes()
        this.checkboxGroupBrand = this.checkboxGroupBrand.filter(
          id => id != this.currentBrandId
        )
        this.brandAreaList = this.brandAreaList.filter(
          item => item.seriesId != this.currentBrandId
        )
      }
    },
    //区域选项触发
    onCaseaderAreaChange() {
      this.$nextTick(() => {
        const [node] = this.$refs.casecaderArea.activePath
        const { checked, value, children } = node
        const list = this.brandAreaList.filter(
          item =>
            !(
              item.seriesId == this.currentBrandId && item.provinceCode == value
            )
        )
        if (checked) {
          this.brandAreaList = [
            ...list,
            { seriesId: this.currentBrandId, provinceCode: value, cityCode: '' }
          ]
          this.checkboxGroupBrand = [
            ...this.checkboxGroupBrand.filter(id => id != this.currentBrandId),
            this.currentBrandId
          ]
        } else {
          this.checkedArea = false
          const bool = children.every(child => child.checked)
          if (!bool) {
            this.brandAreaList = [
              ...list,
              ...children
                .filter(child => child.checked)
                .map(child => ({
                  seriesId: this.currentBrandId,
                  provinceCode: value,
                  cityCode: child.value
                }))
            ]
          } else {
            this.brandAreaList = this.brandAreaList.filter(
              item => item.provinceCode != value
            )
          }
        }
      })
    },
    async complete() {
      try {
        if (this.brandAreaList.length == 0) {
          this.$message.error('请选择需要更改数据的品牌与相对应的城市')
          return
        }
        let userList = this.multipleSelection.map(user => ({
          userId: user.id,
          userCode: user.code
        }))
        this.isLoading = true
        let { code, data } = await Http.editPower({
          userList,
          brandAreaList: this.brandAreaList
        })
        this.isLoading = false
        if (code != 0) return
        this.drawer = false
        this.brandAreaList = []
        this.$message.success('提交成功')
      } catch (error) {
        this.isLoading = false
        console.log(error)
        this.$message.error('提交失败')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.view {
  min-height: 80vh;
}
.input-with-select .el-select {
  width: 100px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.power-list {
  display: flex;
  & > li {
    flex: 1;
    .power-list__title {
      text-align: center;
      line-height: 1.6;
    }
  }
  .checbox-list.el-checkbox-group {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    &.scroll {
      height: 600px;
      z-index: 1;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .el-checkbox {
      margin: 5px 0;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  width: 100%;
}
.search.el-input {
  margin: 10px 20px;
  margin-bottom: 0;
  width: 75%;
}

.casecader-list {
  display: flex;
  .checked-all {
    padding-left: 30px;
  }
  .casecader-brand-checkbox {
    display: flex;
    align-items: center;
    .casecader-label {
      margin-left: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > label {
        color: #606266;
        font-weight: normal;
      }
      &.active > label {
        color: #000;
        font-weight: 600;
      }
    }
  }
}
.casecader-tips {
  font-size: 13px;
  color: #606266;
  padding: 0 20px;
  margin-top: 20px;
  .el-icon-caret-right {
    margin-right: 10px;
  }
  & > label {
    margin-right: 20px;
    font-weight: 400;
  }
}

.cascader-brand::v-deep .el-checkbox {
  display: none;
  &.my-checked {
    display: inline-block;
    .el-checkbox__label {
      display: none;
    }
  }
}

.my-cascader {
  margin: 10px;
  &::v-deep .el-cascader-menu__wrap {
    height: 600px;
  }
}
</style>
