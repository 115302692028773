// excel表格导出
export const excelJsonToExcel = (json, data, filename = '') => {
  const filterVal = json.map(item => item.prop)
  data = formatJson(filterVal, data)
  import('@/vendor/Export2Excel').then(excel => {
    const tHeader = json.map(item => item.title)
    excel.export_json_to_excel({
      header: tHeader, //表头 必填
      data, //具体数据 必填
      filename, //非必填
      autoWidth: false
    })
  })
}

/**
 * 状态值赋值 (对象属性命名必须跟导出字段一致)
 * 注: 当有字段值重复且展示内容不一致时, 定义新的字段名再罗列属性数组对象
 * */
export const keyValueJson = {
  // 门店分类
  storeNature: [
    {
      label: '综合店',
      value: '10'
    },
    {
      label: '单系列店',
      value: '20'
    },
    {
      label: '融合店',
      value: '30'
    },
    {
      label: 'V6寝具店',
      value: '40'
    },
    {
      label: 'V6沙发店',
      value: '50'
    },
    {
      label: '寝具综合店',
      value: '11'
    },
    {
      label: '沙发综合店',
      value: '12'
    },
    {
      label: '客餐卧综合店',
      value: '13'
    },
    {
      label: '寝具单系列店',
      value: '21'
    },
    {
      label: '沙发单系列店',
      value: '22'
    },
    {
      label: '整装店',
      value: '60'
    },
    {
      label: '羡慕融合店',
      value: '70'
    },
    {
      label: '寝具店',
      value: '80'
    },
    {
      label: '大家居店',
      value: '90'
    },
    {
      label: '全屋宅配店（带场景）',
      value: '100'
    },
    {
      label: '全屋宅配店',
      value: '110'
    }
  ],

  // 合作关系
  partnership: [
    {
      label: '直营',
      value: '10'
    },
    {
      label: '经销',
      value: '20'
    },
    {
      label: '零售',
      value: '30'
    },
    {
      label: '电商',
      value: '40'
    },
    {
      label: '工程',
      value: '50'
    },
    {
      label: '地产自营拎包',
      value: '60'
    },
    {
      label: '商超',
      value: '70'
    },
    {
      label: '公司间',
      value: '90'
    }
  ],

  // 门店子系列状态
  ifEnabled: [
    {
      label: '有效',
      value: '0'
    },
    {
      label: '失效',
      value: '1'
    }
  ],

  // 经营模式
  managementModel: [
    {
      label: '一级经销',
      value: '10'
    },
    {
      label: '二级分销',
      value: '20'
    },
    {
      label: '三级分销',
      value: '30'
    },
    {
      label: '直营',
      value: '40'
    }
  ],

  // 模块状态
  independentDoorHeadFlag: [
    { value: '1', label: '有' },
    { value: '0', label: '没有' }
  ],

  // 渠道性质
  shopType: [
    {
      label: 'KA商场店',
      value: 'SJ001'
    },
    {
      label: '独立店',
      value: 'SJ003'
    },
    {
      label: '社区店',
      value: 'SJ004'
    },
    {
      label: '展示店',
      value: 'SJ005'
    },
    {
      label: '新渠道店',
      value: 'SJ007'
    },
    {
      label: '当地商场店',
      value: 'SJ008'
    },
    {
      label: '购物中心店',
      value: 'SJ009'
    },
    {
      label: '装企店',
      value: 'SJ010'
    }
  ],

  // 门店状态
  statusExplain: [
    { label: '待设计', value: '9' },
    { label: '设计中', value: '10' },
    { label: '待装修', value: '15' },
    { label: '装修中', value: '20' },
    { label: '装修完成', value: '30' },
    { label: '正常营业', value: '40' },
    { label: '整改中', value: '45' },
    { label: '装修失效', value: '50' },
    { label: '取消经营', value: '51' },
    { label: '撤店失效', value: '60' },
    { label: '人工失效', value: '61' },
    { label: '系列全失效引起门店失效', value: '62' },
    { label: '转让失效', value: '70' }
  ],

  // 送达方状态
  statusExplain_authorized: [
    { label: '意向', value: '10' },
    { label: '临时授权', value: '20' },
    { label: '正式授权', value: '30' },
    { label: '待淘汰', value: '40' },
    { label: '终止合作', value: '50' }
  ],

  // 营业执照类型
  businessLicenseType: [
    { label: '工商个体户', value: '0' },
    { label: '责任有限公司', value: '1' }
  ],

  // 纳税类型
  payTaxesType: [
    { label: '一般纳税人', value: '0' },
    { label: '定额纳税人', value: '1' },
    { label: '小规模纳税人', value: '2' }
  ],

  // 开票类型
  billType: [
    { label: '增值税专用发票', value: '1' },
    { label: '增值税电子普通发票', value: '2' },
    { label: '增值税普通发票', value: '3' },
    { label: '增值税电子专用发票', value: '4' }
  ],

  // 经销商状态
  dealerStatus: [
    { label: '意向', value: '10' },
    { label: '临时授权', value: '20' },
    { label: '正式授权', value: '30' },
    { label: '待淘汰', value: '40' },
    { label: '终止合作', value: '50' }
  ],

  // 性别
  sex: [
    { label: '保密', value: '0' },
    { label: '男', value: '1' },
    { label: '女', value: '2' }
  ],

  // 婚姻状况
  maritalStatus: [
    { label: '未婚', value: '0' },
    { label: '已婚', value: '1' },
    { label: '离异', value: '2' }
  ],
  // 学历
  education: [
    { value: '10', label: '小学' },
    { value: '11', label: '初中' },
    { value: '12', label: '高中' },
    { value: '13', label: '职高' },
    { value: '14', label: '中技' },
    { value: '15', label: '中专' },
    { value: '16', label: '大专' },
    { value: '17', label: '本科' },
    { value: '18', label: '硕士' },
    { value: '19', label: '博士' },
    { value: '20', label: 'MBA' },
    { value: '21', label: 'EMBA' },
    { value: '22', label: '博士后' },
    { value: '23', label: '其他' }
  ],
  // 渠道类型
  channel: [
    { value: '10', label: '直营' },
    { value: '20', label: '经销' }
  ],
  // 门店位置等级
  shopPositionLevel: [
    { value: 'A+', label: 'A+' },
    { value: 'A', label: 'A' },
    { value: 'B+', label: 'B+' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: '其他', label: '其他' }
  ],
  // 是否海外授权系列标记
  overseasFlag: [
    { value: '1', label: '是' },
    { value: '0', label: '否' }
  ],
  // 是否关闭下单权限
  orderAuthority: [
    { value: '1', label: '默认允许下单' },
    { value: '0', label: '不允许下单' }
  ],
  // 实际撤店原因
  actualWithdrawReason: [
    { value: '0', label: '商场原因' },
    { value: '1', label: '经销商经营原因' },
    { value: '2', label: '换位重装' },
    { value: '3', label: '系统数据修正' },
    { value: '4', label: '换商换位/换系列' },
    { value: '5', label: '店面换系列' }
  ],
  // 装修规则
  decorationStopType: [
    { value: '0', label: '默认重装制度' },
    { value: '1', label: '22年重装制度' },
    { value: '2', label: '其他特殊申请延期重装' }
  ]
}

export const formatJson = (filterVal, jsonData) => {
  const keys = Object.keys(keyValueJson)
  return jsonData.map(v =>
    filterVal.map(j => {
      if (keys.includes(j)) {
        const data = keyValueJson[j].find(item => item.value === String(v[j]))
        if (String(v[j]) && data) {
          return data.label
        } else {
          return v[j]
        }
      } else {
        return v[j]
      }
    })
  )
}
