<template>
  <div>
    <div ref="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <template v-if="item.select">
            <el-select
              v-model="formInline[item.key]"
              placeholder="请选择"
              :filterable="item.search">
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(select, index) in item.options"
                :key="index"
                :label="select.label"
                :value="select.value">
              </el-option>
            </el-select>
          </template>
          <template v-else>
            <el-input
              v-model="formInline[item.key]"
              :placeholder="`请输入${item.label}`"
              @keyup.enter.native="query"></el-input>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button
            @click="downloadExcel"
            v-loading.fullscreen.lock="fullscreenLoading"
            >导出excel</el-button
          >
          <BrandExport
            v-loading.fullscreen.lock="fullscreenLoading"
            @downloadExcel="downloadExcel" />
        </el-form-item>
      </el-form>
    </div>
    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px)` }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        v-loading="loading"
        height="100%">
        <el-table-column prop="licenseNumber" label="售达方编号" width="140" />
        <el-table-column
          prop="businessLicenseName"
          label="售达方名称"
          width="170" />
        <el-table-column prop="ifEnabled" label="售达方状态" width="110">
          <template slot-scope="scope">
            <div>
              {{ scope.row.ifEnabled ? '停用' : '启用' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="实控人" width="120" />
        <el-table-column prop="legalPersonName" label="法人" width="120" />

        <!-- <el-table-column prop="province" label="省份" width="140" />
      <el-table-column prop="city" label="市区" width="140" />
      <el-table-column prop="area" label="乡镇" width="130" /> -->
        <el-table-column
          prop="businessLicenseName"
          label="营业执照名称"
          width="140" />
        <el-table-column prop="creditCode" label="社会信用代码" width="120" />
        <el-table-column prop="registerDate" label="注册日期" width="120" />
        <el-table-column label="授权品牌" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('授权品牌', 'brand', scope.row)">
                查看授权品牌
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联经销商" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('关联经销商', 'business', scope.row)">
                查看经销商
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联送达方" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('关联送达方', 'boss', scope.row)">
                查看送达方
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联门店" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('门店列表', 'shop', scope.row)">
                查看门店
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="getBusinessPageData(scope.row.id)"
                v-permission="['edit_business_info']">
                修改营业执照信息
              </div>
              <div
                class="handle"
                v-permission="['edit_business_brand']"
                @click="
                  showDialog(
                    '新增/修改授权系列',
                    'editAutorzedBrand',
                    scope.row
                  )
                ">
                新增/修改授权系列
              </div>
              <div
                class="handle"
                v-permission="['edit_business_sap']"
                @click="handleExport(scope.row.id)">
                同步到SAP
              </div>
              <div
                v-permission="['business_detail']"
                class="handle"
                @click="handleDetail(scope.row.id)">
                查看详情
              </div>
              <div
                class="handle"
                v-permission="['business_license_flow']"
                @click="
                  showDialog('相关流程', 'dealer_business_license', scope.row)
                ">
                相关流程
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <DialogTable
      :visible.sync="dialogVisible"
      :title="title"
      :type="type"
      :dataList="tableList"
      :onChange="type == 'boss' ? getBossList : getCity"
      :id="id"
      :data="listData"
      :getDataList="getDataList"
      @onSave="handleSave"
      :bossId="dataObject.bossId"
      @onDelete="handleDel" />
  </div>
</template>

<script>
import {
  getBusinessManageList,
  getBrandDataList,
  getBusinessBrand,
  getBusinessShop,
  getBusinessBoss,
  getBusinessDealer,
  getBusinessCity,
  getNewBusinessDeliver,
  getNewBusinessCity,
  getBusinessBrandList,
  saveBusinessBrandData,
  delBusinessBrandData,
  initializeSapDataByDataId,
  excelBusiness,
  excelToBusiness,
  getBossCorrelationInfo
} from '@/api/businessOos'
import { getBusinesslicenseList } from '@/api/common'
import DialogTable from './components/dialogTable'

import BrandExport from './components/brandExport'

import { tableHeight } from '@/mixin/tableHeight'
import { excelJsonToExcel } from './fn'
import { authorizedJsonValue } from './json'
import { getStorage } from '@/utils/storage'
const formInlineData = [
  {
    label: '售达方编号',
    key: 'licenseNumber'
  },
  {
    label: '实控人',
    key: 'realName'
  },
  {
    label: '法人',
    key: 'legalPersonName'
  },
  {
    label: '营业执照名称',
    key: 'businessLicenseName'
  },
  {
    label: '是否海外售达方标记',
    key: 'overseasFlag',
    select: true,
    options: [
      { label: '是', value: 1 },
      { label: '否', value: 0 }
    ]
  }
]
export default {
  components: {
    DialogTable,
    BrandExport
  },
  mixins: [tableHeight],
  data() {
    return {
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      tabsType: 'old',
      cityTyep: 'old',
      id: '',
      listData: {},
      loading: false,
      dataObject: {},
      excelLoding: false,
      fullscreenLoading: false,
      userInfo: getStorage('userInfo') || {}
    }
  },
  created() {
    this.getBrandList()
    this.getDataList()
  },
  methods: {
    async handleExport(id) {
      await initializeSapDataByDataId({ dataId: id, initializeDataType: 30 })
      this.$message.success('同步成功！')
    },
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })

      this.loading = true
      const res = await getBusinessManageList({
        ...this.formInline,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 获取授权系列
    async getBrandList() {
      const res = await getBrandDataList()
      if (res.code === 0) {
        this.formInlineData[2].options = res.data.map(item => {
          return {
            value: item.name,
            label: item.name
          }
        })
      }
    },
    query() {
      // console.log(this.formInline);
      this.page = 1
      this.getDataList()
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.listData = data
      this.id = data.id

      let params = {
        businessLicenseId: data.licenseNumber
      }
      switch (type) {
        case 'brand':
          //查看授权品牌
          this.getBossCorrelationInfo({
            businessLicenseId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'party'
          })
          break
        case 'shop':
          //查看门店
          this.getBossCorrelationInfo({
            businessLicenseId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'shop'
          })
          break
        case 'boss':
          //查看送达方
          this.getBossCorrelationInfo({
            businessLicenseId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'authorized_brand'
          })
          break
        case 'business':
          //查看经销商
          this.getBossCorrelationInfo({
            businessLicenseId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'distributor'
          })
          break
        case 'city':
          this.getCity(data, this.cityTyep)
          break
        case 'editAutorzedBrand':
          this.getEditAuthorizedBrand(data)
          break
        case 'dealer_business_license':
          // this.getEditAuthorizedBrand(data)
          break
      }
    },
    getBossCorrelationInfo(params) {
      getBossCorrelationInfo(params).then(res => {
        this.tableList = res.data
      })
    },
    // 售达方详情
    async getBusinessPageData(id) {
      this.tableList = await getBusinesslicenseList({
        id
      }).then(res => res.data)
      this.showDialog('修改营业执照信息', 'editBusiness', { id })
    },
    // 获取授权品牌
    async getBrandData(id) {
      const res = await getBusinessBrand({
        businessLicenseId: id
      })
      this.tableList = res.data.map(item => {
        return {
          name: item.brandName,
          ...item
        }
      })
    },
    // 获取门店
    async getShopDataList(data) {
      const res = await getBusinessShop({
        businessLicenseId: data.id
      })
      this.tableList = res.data
    },
    // // 获取送达方
    async getBossList(id, type = 'old') {
      this.tabsType = type
      if (type == 'old') {
        // console.log(type);
        const res = await getBusinessBoss({
          businessLicenseId: id
        })
        this.tableList = res.data.map(item => {
          return {
            authorizedName: item.deliveryPartyName,
            ...item
          }
        })
      } else {
        const res = await getNewBusinessDeliver({
          businessLicenseId: id
        })
        console.log(res)
        this.tableList = res.data.map(item => {
          return {
            authorizedName: item.deliveryPartyName,
            ...item
          }
        })
      }
    },
    // // 获取经销商
    async getDealerList(id) {
      const res = await getBusinessDealer({
        businessLicenseId: id
      })
      this.tableList = res.data
    },
    // 获取授权城市
    async getCity(data, type = 'old') {
      this.cityTyep = type
      if (type == 'old') {
        const res = await getBusinessCity({
          businessLicenseId: data.id,
          bossId: data.bossId
        })
        this.tableList = res.data
      } else {
        const res = await getNewBusinessCity({
          businessLicenseId: data.id
        })
        this.tableList = res.data
      }
    },
    // 获取系列数据
    async getEditAuthorizedBrand(data) {
      this.dataObject = data
      const res = await getBusinessBrandList({
        businessLicenseId: data.id
      })
      this.tableList = res.data
    },
    async handleSave(form) {
      form.bossId = this.dataObject.bossId
      const res = await saveBusinessBrandData(form)
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '保存成功!'
        })
        this.getEditAuthorizedBrand(this.dataObject)
      }
    },
    async handleDel(ids) {
      const res = await delBusinessBrandData(ids.join(','))
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '失效成功!'
        })
        this.getEditAuthorizedBrand(this.dataObject)
      }
    },
    // 导出excel
    async downloadExcel(val) {
      // await excelBusiness(this.formInline)
      this.fullscreenLoading = true
      try {
        excelToBusiness({
          cityLevelBrand: val,
          accountId: this.userInfo.accountId
        })
        this.$message.success('excel导出成功,文件生成中...')
        // const { data } = await excelToBusiness()
        // excelJsonToExcel(authorizedJsonValue, data, '售达方列表')
      } catch (error) {
        this.$message.error('excel导出失败')
      } finally {
        this.fullscreenLoading = false
      }
    },
    // 查看详情
    async handleDetail(id) {
      this.tableList = await getBusinesslicenseList({
        id
      }).then(res => res.data)
      this.showDialog('售达方详情', 'businessDetail', { id })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';

.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
